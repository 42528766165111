@use "sass:math";

//
// Configuration
//

// Target modern browsers.
$global-flexbox: true;

// Align breakpoints with $global-width and Bootstrap's viewport sizes.
$breakpoint-classes: (small medium large);
$breakpoints: (
  // phone
    small: 0,
  // tablet
    medium: 768px,
  // laptop
    large: 992px,
  // constrained ($global-width)
    final: 1200px,
  // 1900px is a common viewport width
    wide: 1800px
);

$vertical-bar-thickness: 0.5rem;
$grid-container-padding: (
  small: 2 * 1rem,
  medium: 2 * 1rem * math.div(3, 2),
);

// Use brand font with native fallback.
$native-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$body-font-family: "Source Sans Pro", $native-font-stack;

// Use heaver bold weight.
$global-weight-bold: 900;

// Style headings. (See usage notes in typography stylesheet.)
$header-font-weight: 700;
$header-styles: (
  small: (
    h1: (
      font-size: 20,
    ),
    h2: (
      font-size: 20,
      margin-bottom: 1rem,
    ),
    h3: (
      font-size: 18,
    ),
    h4: (
      font-size: 18,
    ),
    h5: (
      font-size: 16 // $global-font-size,
    ),
    h6: (
      font-size: 14 // $global-font-size - 4,
    ),
  ),
  medium: (
    h1: (
      font-size: 32,
    ),
    h2: (
      font-size: 32,
      margin-bottom: 1rem,
    ),
    h3: (
      font-size: 24,
    ),
    h4: (
      font-size: 18,
    ),
    h5: (
      font-size: 16 // $global-font-size,
    ),
    h6: (
      font-size: 14 // $global-font-size - 2,
    ),
  ),
);

// Customize colors.
$foundation-palette: (
  primary: $nep-red,
  secondary: $nep-gray,
  subtle: #e5e5e5,
  success: #3adb76,
  warning: #ffae00,
  alert: $nep-red,
);
$anchor-color: inherit;
$anchor-color-hover: inherit;

// Configure accordion.
$accordion-item-color: inherit;
$accordion-title-font-size: inherit;

// Configure blockquotes.
$blockquote-border: none;
$blockquote-color: inherit;
$blockquote-padding: 1rem 3rem 0 3rem;
$cite-color: inherit;

// Configure callouts.
$callout-background-fade: 0%;
$callout-border: none;
$callout-font-color-alt: $dark-body-font-color;
$callout-link-tint: false;
$callout-margin: 0 auto 1rem auto;
$callout-padding: 1rem 1rem 0 1rem;
$callout-radius: 2px;

// Configure cards.
$card-background: #f6f6f6;
$card-border: none;
$card-border-radius: 2pt;
$card-shadow: 0 0 calc(1rem * 1 / 3) #0003;

// Configure labels.
$label-radius: 2pt;
$label-padding: calc(1rem * 1 / 3 - 0.1rem) calc(1rem * 1 / 2) calc(1rem * 1 / 3);

// Configure forms.
$form-label-color: inherit;
$form-label-font-size: inherit;
$form-label-font-weight: inherit;
$form-label-line-height: inherit;

// Configure tables.
$table-border: none;

//
// Imports
//

@import "foundation-sites/scss/foundation.scss";

// Global
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grid (at most one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
// @include foundation-button-group;
// @include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
@include foundation-table;

// Basic components
@include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
// @include foundation-dropdown;
@include foundation-pagination;
// @include foundation-tooltip;

// Containers
@include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;

// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
// @include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

//
// Patches
//

// Limit the scope of citation block styling.
// Upstream: https://github.com/zurb/foundation-sites/blob/v6.4.3/scss/typography/_base.scss#L460
cite {
  // Unapply upstream
  display: inline;
  font-size: inherit;
  color: inherit;
  &::before {
    content: none;
  }

  // Scoped reapply upstream
  blockquote & {
    display: block;
    font-size: $cite-font-size;
    color: $cite-color;
    &::before {
      content: $cite-pseudo-content;
    }
  }

  // Default style
  font-style: italic;
}

label {
  margin: unset;
}

.label {
  vertical-align: 0.1rem;
}

.pagination {
  button {
    cursor: pointer;
    line-height: inherit;
  }
}

//
// Extensions
//

// Media-dependent lead
@each $size in $breakpoint-classes {
  @include breakpoint($size) {
    .#{$size}-lead {
      // zurb/foundation-sites@v6.5.3:/scss/typography/_helpers.scss:57
      font-size: $lead-font-size;
      line-height: $lead-lineheight;
    }
  }
}

@each $color in (secondary, primary) {
  .text-#{$color} {
    color: get-color($color);
  }
}
