@use "sass:math";

// See also Foundation Sites configuration.

// Style links.
a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  .icon-grid-label &,
  .inline-content &,
  p &,
  .p &,
  ol &,
  ul:not(.pagination) & {
    text-decoration-color: rgba($body-font-color, math.div(1, 5));
    text-decoration-line: underline;
    transition-duration: 100ms;
    transition-property: color, text-decoration-color;
    transition-timing-function: ease-out;

    &:focus,
    &:hover {
      color: $link-blue;
      text-decoration-color: rgba($link-blue, math.div(1, 2));
      transition-duration: 50ms;
    }

    &:visited {
      &:focus,
      &:hover {
        color: $link-purple;
        text-decoration-color: rgba($link-purple, math.div(1, 2));
      }
    }

    @include if-dark {
      text-decoration-color: rgba($dark-body-font-color, math.div(1, 3));

      &:focus,
      &:hover {
        color: $dark-link-blue;
        text-decoration-color: rgba($dark-link-blue, math.div(1, 2));
      }

      &:visited {
        &:focus,
        &:hover {
          color: $dark-link-purple;
          text-decoration-color: rgba($dark-link-purple, math.div(1, 2));
        }
      }
    }

    .callout.health-check & {
      color: $dark-body-font-color;
      text-decoration-color: rgba($dark-body-font-color, math.div(1, 3));
    }
  }
}

a.non-discoverable {
  cursor: default;
}

abbr {
  @include if-dark {
    border-bottom-color: $dark-body-font-color;
  }
}

.article-body:not(.continued) > p:not(.no-drop-cap):first-of-type::first-letter {
  float: left;
  font-size: calc(1em * #{$paragraph-lineheight} * 2);
  font-weight: 300;
  line-height: 1;
  margin-bottom: calc(-1 * 1em * (#{$paragraph-lineheight} - 1) / 2);
  margin-right: calc(1em * (#{$paragraph-lineheight} - 1) / 6);
}

.article-content {
  font-family: $article-font-family;

  @include breakpoint(medium) {
    font-size: 1rem * math.div(14, 12);
  }
}

.article-head {
  margin-bottom: 3em;
  text-align: center;

  .phrasing-block {
    margin-left: auto;
    margin-right: auto;
  }
}

blockquote {
  overflow: hidden;
  padding-left: 3rem;
  position: relative;

  &::before {
    content: "“";
    font-family: "Arial", sans-serif;
    font-size: 8rem;
    font-weight: 900;
    left: 0;
    opacity: 0.125;
    position: absolute;
    top: -3rem;
  }
}

// Heavy, short, and wide all-caps
@mixin caps($context: 1em) {
  font-size: calc(#{$context} * 4 / 5);
  font-weight: 900;
  letter-spacing: calc(1ch * 1 / 6);
  text-transform: uppercase;
}
.caps {
  @include caps;
}

.card {
  // Undo
  @include if-article {
    font-family: $body-font-family;
    font-size: 1rem;
    font-weight: $global-weight-normal;
  }
}

// Columns
@each $columns in (2 3) {
  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      @if $size == $-zf-zero-breakpoint {
        .columns-#{$columns} {
          columns: $columns;
          column-gap: calc(#{$global-margin} + 1.5em);
        }
      } @else {
        .#{$size}-columns-#{$columns} {
          columns: $columns;
          column-gap: calc(#{$global-margin} + 1.5em);
        }
      }
    }
  }
}

.field-label {
  @include caps;

  $margin: 0.5rem;

  color: mix($body-font-color, $body-muted-font-color, 33%);
  display: block;
  margin-bottom: $margin;

  @include if-dark {
    color: mix($dark-body-font-color, $dark-body-muted-font-color, 50%);
  }

  & + input[type="email"].hollow,
  & + input[type="tel"].hollow,
  & + input[type="text"].hollow,
  & + textarea.hollow {
    margin-top: -1 * $margin;
  }
}

// Headings:
//
//   - h1: Title of main content
//   - h2: Top-level heading within body of main content
//   - h3: Second-level heading within body of main content
//   - h4: Third-level heading within body of main content
//   - h5: Fourth-level heading within body of main content (not recommended)
//   - h6: Fifth-level heading within body of main content (not recommended)
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
}
h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p),
.h1 {
  @include if-article {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 1.75rem;
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: 2.5rem;
    }
  }
}
h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p),
.h1,
h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p),
.h2 {
  position: relative;

  @include if-article {
    @include breakpoint(medium) {
      margin-top: 1em;
    }
  }

  &:not(.plain) {
    $bar-margin: 1rem;
    $bar-overhang: 1rem * math.div(1, 3);
    $bar-thickness: (
      small: 1rem * math.div(1, 3),
      medium: 1rem * math.div(1, 2),
    );

    clear: left;
    padding-bottom: $bar-overhang;
    padding-top: $bar-overhang;

    &::before {
      background-color: $body-font-color;
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      top: 0;

      @include if-dark {
        background-color: $dark-body-font-color;
      }

      @each $size in (small, medium) {
        @include breakpoint($size) {
          left: calc(
            -1 * (
                #{rem-calc(map-get($grid-container-padding, $size))} / 2 + #{map-get(
                    $bar-thickness,
                    $size
                  )}
              ) / 2
          );
          width: map-get($bar-thickness, $size);
        }
      }

      @include breakpoint(final) {
        left: calc(-1 * (#{map-get($bar-thickness, medium)} + #{$bar-margin}));
      }
    }
  }

  .caps {
    font-size: rem-calc(map-get($button-sizes, default));

    @include breakpoint(medium) {
      font-size: calc(1ex * 8 / 9);

      @include if-article {
        font-size: calc(1ex * 2 / 3);
      }
    }
  }

  .phrasing-block {
    display: block;
    margin-bottom: calc(1em * 1 / 3);
  }

  .subtitle {
    font-size: 1rem;
    margin-top: calc(1em * 1 / 3);

    @include breakpoint(medium) {
      font-size: 1rem * math.div(14, 12);
    }
  }
}
h4:not(.h1):not(.h2):not(.h3):not(.h5):not(.h6):not(.p),
.h4 {
  @each $size in (small, medium) {
    @include breakpoint($size) {
      @include caps(rem-calc(map-deep-get($header-styles, $size, h4, font-size)));
    }
  }
}
h6:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.p),
.h6 {
  opacity: calc(4 / 5);
}

// Unstyled horizontal list
@mixin inline-list {
  display: inline;
  list-style: none;
  margin-left: 0;

  > li {
    display: inline;
  }
}
.inline-list {
  @include inline-list;
}

// Remove margins
@each $size in $breakpoint-classes {
  @include breakpoint($size) {
    @if $size == $-zf-zero-breakpoint {
      .no-margin-bottom {
        margin-bottom: 0;
      }
    } @else {
      .#{$size}-no-margin-bottom {
        margin-bottom: 0;
      }
    }
  }
}

// Typeset as a paragraph
address,
figure,
.p,
.p-margin,
p {
  display: block;
  margin-bottom: $paragraph-margin-bottom;

  @include if-article {
    margin-bottom: 1em;
  }
}

// Mimic paragraph margins.
@each $size in $breakpoint-classes {
  @include breakpoint($size) {
    @if $size == $-zf-zero-breakpoint {
      .p-margin {
        margin-bottom: $paragraph-margin-bottom;
      }
    } @else {
      .#{$size}-p-margin {
        margin-bottom: $paragraph-margin-bottom;
      }
    }
  }
}

// Block layout within phrasing content for e.g. multi-part headings
span.phrasing-block {
  display: block;
}

// Preserve newlines
@each $size in $breakpoint-classes {
  @include breakpoint($size) {
    @if $size == $-zf-zero-breakpoint {
      .preserve-lines {
        white-space: pre;
      }
    } @else {
      .#{$size}-preserve-lines {
        white-space: pre;
      }
    }
  }
}

small {
  @include if-article {
    display: block;
    font-style: italic;
    text-align: center;
  }
}

.text-muted {
  color: $body-muted-font-color;

  @include if-dark {
    color: $dark-body-muted-font-color;
  }
}

ol,
ul {
  @include if-article {
    margin-left: calc(#{$list-side-margin} + 0.5rem);
  }
}
