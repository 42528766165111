body.logged-in {
  .article-content,
  .item-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:empty::after {
        color: #f0f;
        content: "ℍ";
        display: block;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1;
      }

      b,
      strong {
        -webkit-text-stroke: 1pt #f0f;
      }
    }

    a[target="_blank"] {
      border-bottom: 2pt solid #f0f !important;;
    }

    img[class^="wp-image-"],
    img[class*=" wp-image-"] {
      &:not([class^="size-"]):not([class*=" size-"]) {
        outline: 4pt solid #f0f;
      }
    }

    p:empty::after {
      color: #f0f;
      content: "¶";
      display: block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1;
    }
  }

  span.visible-br::before {
    background-color: #f0f;
    color: #fff;
    content: "↵";
    font-weight: bold;
  }

  span.visible-space {
    background-color: #f0f;
    border-right: 2pt solid #f0f;
  }
}
