html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

[data-whatintent="mouse"] *, [data-whatintent="mouse"] :focus, [data-whatintent="touch"] *, [data-whatintent="touch"] :focus, [data-whatinput="mouse"] *, [data-whatinput="mouse"] :focus, [data-whatinput="touch"] *, [data-whatinput="touch"] :focus {
  outline: none;
}

[draggable="false"] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.foundation-mq {
  font-family: "small=0em&medium=48em&large=62em&final=75em&wide=112.5em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fefefe;
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  line-height: 1.5;
}

img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

textarea {
  border-radius: 0;
  height: auto;
  min-height: 50px;
}

select {
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
}

.map_canvas img, .map_canvas embed, .map_canvas object, .mqa-display img, .mqa-display embed, .mqa-display object {
  max-width: none !important;
}

button {
  appearance: none;
  cursor: auto;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  line-height: 1;
}

[data-whatinput="mouse"] button {
  outline: 0;
}

pre {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {
  box-sizing: border-box;
  color: #0a0a0a;
  appearance: none;
  background-color: #fefefe;
  border: 1px solid #cacaca;
  border-radius: 0;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 2px #0a0a0a1a;
}

[type="text"]:focus, [type="password"]:focus, [type="date"]:focus, [type="datetime"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="week"]:focus, [type="email"]:focus, [type="number"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="url"]:focus, [type="color"]:focus, textarea:focus {
  background-color: #fefefe;
  border: 1px solid #8a8a8a;
  outline: none;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  box-shadow: 0 0 5px #cacaca;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
  cursor: not-allowed;
  background-color: #e6e6e6;
}

[type="submit"], [type="button"] {
  appearance: none;
  border-radius: 0;
}

input[type="search"] {
  box-sizing: border-box;
}

::placeholder {
  color: #cacaca;
}

[type="file"], [type="checkbox"], [type="radio"] {
  margin: 0 0 1rem;
}

[type="checkbox"] + label, [type="radio"] + label {
  vertical-align: baseline;
  margin-bottom: 0;
  margin-left: .5rem;
  margin-right: 1rem;
  display: inline-block;
}

[type="checkbox"] + label[for], [type="radio"] + label[for] {
  cursor: pointer;
}

label > [type="checkbox"], label > [type="radio"] {
  margin-right: .5rem;
}

[type="file"] {
  width: 100%;
}

label {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  display: block;
}

label.middle {
  margin: 0 0 1rem;
  padding: .5625rem 0;
  line-height: 1.5;
}

.help-text {
  color: #0a0a0a;
  margin-top: -.5rem;
  font-size: .8125rem;
  font-style: italic;
}

.input-group {
  align-items: stretch;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
}

.input-group > :first-child, .input-group > :first-child.input-group-button > *, .input-group > :last-child, .input-group > :last-child.input-group-button > * {
  border-radius: 0;
}

.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  white-space: nowrap;
  margin: 0;
}

.input-group-label {
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  background: #e6e6e6;
  border: 1px solid #cacaca;
  flex: none;
  align-items: center;
  padding: 0 1rem;
  display: flex;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0;
  min-width: 0;
}

.input-group-button {
  text-align: center;
  flex: none;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label {
  align-self: stretch;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  max-width: 100%;
  margin-bottom: .5rem;
}

.fieldset {
  border: 1px solid #cacaca;
  margin: 1.125rem 0;
  padding: 1.25rem;
}

.fieldset legend {
  margin: 0 0 0 -.1875rem;
  padding: 0 .1875rem;
}

select {
  appearance: none;
  color: #0a0a0a;
  background-color: #fefefe;
  border: 1px solid #cacaca;
  border-radius: 0;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  transition: box-shadow .5s, border-color .25s ease-in-out;
}

select:focus {
  background-color: #fefefe;
  border: 1px solid #8a8a8a;
  outline: none;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  box-shadow: 0 0 5px #cacaca;
}

select:disabled {
  cursor: not-allowed;
  background-color: #e6e6e6;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  background-image: none;
  height: auto;
}

select:not([multiple]) {
  padding-top: 0;
  padding-bottom: 0;
}

.is-invalid-input:not(:focus) {
  background-color: #f9e9eb;
  border-color: #cc2e3d;
}

.is-invalid-input:not(:focus)::placeholder, .is-invalid-label {
  color: #cc2e3d;
}

.form-error {
  color: #cc2e3d;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: 900;
  display: none;
}

.form-error.is-visible {
  display: block;
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

p {
  font-size: inherit;
  text-rendering: optimizelegibility;
  margin-bottom: 1rem;
  line-height: 1.6;
}

em, i {
  font-style: italic;
  line-height: inherit;
}

strong, b {
  font-weight: 900;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: inherit;
  text-rendering: optimizelegibility;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-style: normal;
  font-weight: 700;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small {
  color: #cacaca;
  line-height: 0;
}

h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

h2, .h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

h3, .h3, h4, .h4 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.125rem;
  line-height: 1.4;
}

h5, .h5 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1rem;
  line-height: 1.4;
}

h6, .h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.4;
}

@media print, screen and (min-width: 48em) {
  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.5rem;
  }

  h4, .h4 {
    font-size: 1.125rem;
  }

  h5, .h5 {
    font-size: 1rem;
  }

  h6, .h6 {
    font-size: .875rem;
  }
}

a {
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

a:hover, a:focus {
  color: inherit;
}

a img {
  border: 0;
}

hr {
  clear: both;
  border: 0;
  border-bottom: 1px solid #cacaca;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
}

ul, ol, dl {
  margin-bottom: 1rem;
  line-height: 1.6;
  list-style-position: outside;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: .3rem;
  font-weight: 900;
}

blockquote {
  border-left: none;
  margin: 0 0 1rem;
  padding: 1rem 3rem 0;
}

blockquote, blockquote p {
  color: inherit;
  line-height: 1.6;
}

abbr, abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #0a0a0a;
  text-decoration: none;
}

figure {
  margin: 0;
}

kbd {
  color: #0a0a0a;
  background-color: #e6e6e6;
  margin: 0;
  padding: .125rem .25rem 0;
  font-family: Consolas, Liberation Mono, Courier, monospace;
}

.subheader {
  color: #8a8a8a;
  margin-top: .2rem;
  margin-bottom: .5rem;
  font-weight: normal;
  line-height: 1.4;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.cite-block, cite {
  color: inherit;
  font-size: .8125rem;
  display: block;
}

.cite-block:before, cite:before {
  content: "— ";
}

.code-inline, code {
  color: #0a0a0a;
  word-wrap: break-word;
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  max-width: 100%;
  padding: .125rem .3125rem .0625rem;
  font-family: Consolas, Liberation Mono, Courier, monospace;
  font-weight: normal;
  display: inline;
}

.code-block {
  color: #0a0a0a;
  white-space: pre;
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  margin-bottom: 1.5rem;
  padding: 1rem;
  font-family: Consolas, Liberation Mono, Courier, monospace;
  font-weight: normal;
  display: block;
  overflow: auto;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    print-color-adjust: economy;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print, th.show-for-print {
    display: table-cell !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #8a8a8a;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: .5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}

.grid-container {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media print, screen and (min-width: 48em) {
  .grid-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.grid-container.fluid {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media print, screen and (min-width: 48em) {
  .grid-container.fluid {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.grid-container.full {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.grid-x {
  flex-flow: wrap;
  display: flex;
}

.cell {
  flex: none;
  width: 100%;
  min-width: 0;
  min-height: 0;
}

.cell.auto {
  flex: 1 1 0;
}

.cell.shrink {
  flex: none;
}

.grid-x > .auto, .grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 62em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}

.grid-x > .small-12, .grid-x > .small-11, .grid-x > .small-10, .grid-x > .small-9, .grid-x > .small-8, .grid-x > .small-7, .grid-x > .small-6, .grid-x > .small-5, .grid-x > .small-4, .grid-x > .small-3, .grid-x > .small-2, .grid-x > .small-1 {
  flex: none;
}

.grid-x > .small-1 {
  width: 8.33333%;
}

.grid-x > .small-2 {
  width: 16.6667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.3333%;
}

.grid-x > .small-5 {
  width: 41.6667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.3333%;
}

.grid-x > .small-8 {
  width: 66.6667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.3333%;
}

.grid-x > .small-11 {
  width: 91.6667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-auto {
    flex: 1 1 0;
    width: auto;
  }

  .grid-x > .medium-12, .grid-x > .medium-11, .grid-x > .medium-10, .grid-x > .medium-9, .grid-x > .medium-8, .grid-x > .medium-7, .grid-x > .medium-6, .grid-x > .medium-5, .grid-x > .medium-4, .grid-x > .medium-3, .grid-x > .medium-2, .grid-x > .medium-1, .grid-x > .medium-shrink {
    flex: none;
  }

  .grid-x > .medium-shrink {
    width: auto;
  }

  .grid-x > .medium-1 {
    width: 8.33333%;
  }

  .grid-x > .medium-2 {
    width: 16.6667%;
  }

  .grid-x > .medium-3 {
    width: 25%;
  }

  .grid-x > .medium-4 {
    width: 33.3333%;
  }

  .grid-x > .medium-5 {
    width: 41.6667%;
  }

  .grid-x > .medium-6 {
    width: 50%;
  }

  .grid-x > .medium-7 {
    width: 58.3333%;
  }

  .grid-x > .medium-8 {
    width: 66.6667%;
  }

  .grid-x > .medium-9 {
    width: 75%;
  }

  .grid-x > .medium-10 {
    width: 83.3333%;
  }

  .grid-x > .medium-11 {
    width: 91.6667%;
  }

  .grid-x > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 62em) {
  .grid-x > .large-auto {
    flex: 1 1 0;
    width: auto;
  }

  .grid-x > .large-12, .grid-x > .large-11, .grid-x > .large-10, .grid-x > .large-9, .grid-x > .large-8, .grid-x > .large-7, .grid-x > .large-6, .grid-x > .large-5, .grid-x > .large-4, .grid-x > .large-3, .grid-x > .large-2, .grid-x > .large-1, .grid-x > .large-shrink {
    flex: none;
  }

  .grid-x > .large-shrink {
    width: auto;
  }

  .grid-x > .large-1 {
    width: 8.33333%;
  }

  .grid-x > .large-2 {
    width: 16.6667%;
  }

  .grid-x > .large-3 {
    width: 25%;
  }

  .grid-x > .large-4 {
    width: 33.3333%;
  }

  .grid-x > .large-5 {
    width: 41.6667%;
  }

  .grid-x > .large-6 {
    width: 50%;
  }

  .grid-x > .large-7 {
    width: 58.3333%;
  }

  .grid-x > .large-8 {
    width: 66.6667%;
  }

  .grid-x > .large-9 {
    width: 75%;
  }

  .grid-x > .large-10 {
    width: 83.3333%;
  }

  .grid-x > .large-11 {
    width: 91.6667%;
  }

  .grid-x > .large-12 {
    width: 100%;
  }
}

.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-margin-x {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

.grid-margin-x > .cell {
  width: calc(100% - 1.25rem);
  margin-left: .625rem;
  margin-right: .625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: .9375rem;
    margin-right: .9375rem;
  }
}

.grid-margin-x > .auto, .grid-margin-x > .shrink {
  width: auto;
}

.grid-margin-x > .small-1 {
  width: calc(8.33333% - 1.25rem);
}

.grid-margin-x > .small-2 {
  width: calc(16.6667% - 1.25rem);
}

.grid-margin-x > .small-3 {
  width: calc(25% - 1.25rem);
}

.grid-margin-x > .small-4 {
  width: calc(33.3333% - 1.25rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.6667% - 1.25rem);
}

.grid-margin-x > .small-6 {
  width: calc(50% - 1.25rem);
}

.grid-margin-x > .small-7 {
  width: calc(58.3333% - 1.25rem);
}

.grid-margin-x > .small-8 {
  width: calc(66.6667% - 1.25rem);
}

.grid-margin-x > .small-9 {
  width: calc(75% - 1.25rem);
}

.grid-margin-x > .small-10 {
  width: calc(83.3333% - 1.25rem);
}

.grid-margin-x > .small-11 {
  width: calc(91.6667% - 1.25rem);
}

.grid-margin-x > .small-12 {
  width: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 48em) {
  .grid-margin-x > .auto, .grid-margin-x > .shrink {
    width: auto;
  }

  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.875rem);
  }

  .grid-margin-x > .small-2 {
    width: calc(16.6667% - 1.875rem);
  }

  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x > .small-4 {
    width: calc(33.3333% - 1.875rem);
  }

  .grid-margin-x > .small-5 {
    width: calc(41.6667% - 1.875rem);
  }

  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x > .small-7 {
    width: calc(58.3333% - 1.875rem);
  }

  .grid-margin-x > .small-8 {
    width: calc(66.6667% - 1.875rem);
  }

  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }

  .grid-margin-x > .small-10 {
    width: calc(83.3333% - 1.875rem);
  }

  .grid-margin-x > .small-11 {
    width: calc(91.6667% - 1.875rem);
  }

  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x > .medium-auto, .grid-margin-x > .medium-shrink {
    width: auto;
  }

  .grid-margin-x > .medium-1 {
    width: calc(8.33333% - 1.875rem);
  }

  .grid-margin-x > .medium-2 {
    width: calc(16.6667% - 1.875rem);
  }

  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x > .medium-4 {
    width: calc(33.3333% - 1.875rem);
  }

  .grid-margin-x > .medium-5 {
    width: calc(41.6667% - 1.875rem);
  }

  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x > .medium-7 {
    width: calc(58.3333% - 1.875rem);
  }

  .grid-margin-x > .medium-8 {
    width: calc(66.6667% - 1.875rem);
  }

  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.875rem);
  }

  .grid-margin-x > .medium-10 {
    width: calc(83.3333% - 1.875rem);
  }

  .grid-margin-x > .medium-11 {
    width: calc(91.6667% - 1.875rem);
  }

  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 62em) {
  .grid-margin-x > .large-auto, .grid-margin-x > .large-shrink {
    width: auto;
  }

  .grid-margin-x > .large-1 {
    width: calc(8.33333% - 1.875rem);
  }

  .grid-margin-x > .large-2 {
    width: calc(16.6667% - 1.875rem);
  }

  .grid-margin-x > .large-3 {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x > .large-4 {
    width: calc(33.3333% - 1.875rem);
  }

  .grid-margin-x > .large-5 {
    width: calc(41.6667% - 1.875rem);
  }

  .grid-margin-x > .large-6 {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x > .large-7 {
    width: calc(58.3333% - 1.875rem);
  }

  .grid-margin-x > .large-8 {
    width: calc(66.6667% - 1.875rem);
  }

  .grid-margin-x > .large-9 {
    width: calc(75% - 1.875rem);
  }

  .grid-margin-x > .large-10 {
    width: calc(83.3333% - 1.875rem);
  }

  .grid-margin-x > .large-11 {
    width: calc(91.6667% - 1.875rem);
  }

  .grid-margin-x > .large-12 {
    width: calc(100% - 1.875rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-padding-x .grid-padding-x {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

.grid-container:not(.full) > .grid-padding-x {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

.grid-padding-x > .cell {
  padding-left: .625rem;
  padding-right: .625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-padding-x > .cell {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.3333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.6667%;
}

.small-up-7 > .cell {
  width: 14.2857%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 48em) {
  .medium-up-1 > .cell {
    width: 100%;
  }

  .medium-up-2 > .cell {
    width: 50%;
  }

  .medium-up-3 > .cell {
    width: 33.3333%;
  }

  .medium-up-4 > .cell {
    width: 25%;
  }

  .medium-up-5 > .cell {
    width: 20%;
  }

  .medium-up-6 > .cell {
    width: 16.6667%;
  }

  .medium-up-7 > .cell {
    width: 14.2857%;
  }

  .medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 62em) {
  .large-up-1 > .cell {
    width: 100%;
  }

  .large-up-2 > .cell {
    width: 50%;
  }

  .large-up-3 > .cell {
    width: 33.3333%;
  }

  .large-up-4 > .cell {
    width: 25%;
  }

  .large-up-5 > .cell {
    width: 20%;
  }

  .large-up-6 > .cell {
    width: 16.6667%;
  }

  .large-up-7 > .cell {
    width: 14.2857%;
  }

  .large-up-8 > .cell {
    width: 12.5%;
  }
}

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.3333% - 1.25rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.6667% - 1.25rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.2857% - 1.25rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem);
}

@media print, screen and (min-width: 48em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333% - 1.875rem);
  }

  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.6667% - 1.875rem);
  }

  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857% - 1.875rem);
  }

  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }

  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.3333% - 1.875rem);
  }

  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.6667% - 1.875rem);
  }

  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857% - 1.875rem);
  }

  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}

@media print, screen and (min-width: 62em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.3333% - 1.875rem);
  }

  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.6667% - 1.875rem);
  }

  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.2857% - 1.875rem);
  }

  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}

.small-margin-collapse, .small-margin-collapse > .cell {
  margin-left: 0;
  margin-right: 0;
}

.small-margin-collapse > .small-1 {
  width: 8.33333%;
}

.small-margin-collapse > .small-2 {
  width: 16.6667%;
}

.small-margin-collapse > .small-3 {
  width: 25%;
}

.small-margin-collapse > .small-4 {
  width: 33.3333%;
}

.small-margin-collapse > .small-5 {
  width: 41.6667%;
}

.small-margin-collapse > .small-6 {
  width: 50%;
}

.small-margin-collapse > .small-7 {
  width: 58.3333%;
}

.small-margin-collapse > .small-8 {
  width: 66.6667%;
}

.small-margin-collapse > .small-9 {
  width: 75%;
}

.small-margin-collapse > .small-10 {
  width: 83.3333%;
}

.small-margin-collapse > .small-11 {
  width: 91.6667%;
}

.small-margin-collapse > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 48em) {
  .small-margin-collapse > .medium-1 {
    width: 8.33333%;
  }

  .small-margin-collapse > .medium-2 {
    width: 16.6667%;
  }

  .small-margin-collapse > .medium-3 {
    width: 25%;
  }

  .small-margin-collapse > .medium-4 {
    width: 33.3333%;
  }

  .small-margin-collapse > .medium-5 {
    width: 41.6667%;
  }

  .small-margin-collapse > .medium-6 {
    width: 50%;
  }

  .small-margin-collapse > .medium-7 {
    width: 58.3333%;
  }

  .small-margin-collapse > .medium-8 {
    width: 66.6667%;
  }

  .small-margin-collapse > .medium-9 {
    width: 75%;
  }

  .small-margin-collapse > .medium-10 {
    width: 83.3333%;
  }

  .small-margin-collapse > .medium-11 {
    width: 91.6667%;
  }

  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 62em) {
  .small-margin-collapse > .large-1 {
    width: 8.33333%;
  }

  .small-margin-collapse > .large-2 {
    width: 16.6667%;
  }

  .small-margin-collapse > .large-3 {
    width: 25%;
  }

  .small-margin-collapse > .large-4 {
    width: 33.3333%;
  }

  .small-margin-collapse > .large-5 {
    width: 41.6667%;
  }

  .small-margin-collapse > .large-6 {
    width: 50%;
  }

  .small-margin-collapse > .large-7 {
    width: 58.3333%;
  }

  .small-margin-collapse > .large-8 {
    width: 66.6667%;
  }

  .small-margin-collapse > .large-9 {
    width: 75%;
  }

  .small-margin-collapse > .large-10 {
    width: 83.3333%;
  }

  .small-margin-collapse > .large-11 {
    width: 91.6667%;
  }

  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-left: 0;
  margin-right: 0;
}

.small-padding-collapse > .cell {
  padding-left: 0;
  padding-right: 0;
}

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse, .medium-margin-collapse > .cell {
    margin-left: 0;
    margin-right: 0;
  }

  .medium-margin-collapse > .small-1 {
    width: 8.33333%;
  }

  .medium-margin-collapse > .small-2 {
    width: 16.6667%;
  }

  .medium-margin-collapse > .small-3 {
    width: 25%;
  }

  .medium-margin-collapse > .small-4 {
    width: 33.3333%;
  }

  .medium-margin-collapse > .small-5 {
    width: 41.6667%;
  }

  .medium-margin-collapse > .small-6 {
    width: 50%;
  }

  .medium-margin-collapse > .small-7 {
    width: 58.3333%;
  }

  .medium-margin-collapse > .small-8 {
    width: 66.6667%;
  }

  .medium-margin-collapse > .small-9 {
    width: 75%;
  }

  .medium-margin-collapse > .small-10 {
    width: 83.3333%;
  }

  .medium-margin-collapse > .small-11 {
    width: 91.6667%;
  }

  .medium-margin-collapse > .small-12 {
    width: 100%;
  }

  .medium-margin-collapse > .medium-1 {
    width: 8.33333%;
  }

  .medium-margin-collapse > .medium-2 {
    width: 16.6667%;
  }

  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }

  .medium-margin-collapse > .medium-4 {
    width: 33.3333%;
  }

  .medium-margin-collapse > .medium-5 {
    width: 41.6667%;
  }

  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }

  .medium-margin-collapse > .medium-7 {
    width: 58.3333%;
  }

  .medium-margin-collapse > .medium-8 {
    width: 66.6667%;
  }

  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }

  .medium-margin-collapse > .medium-10 {
    width: 83.3333%;
  }

  .medium-margin-collapse > .medium-11 {
    width: 91.6667%;
  }

  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 62em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%;
  }

  .medium-margin-collapse > .large-2 {
    width: 16.6667%;
  }

  .medium-margin-collapse > .large-3 {
    width: 25%;
  }

  .medium-margin-collapse > .large-4 {
    width: 33.3333%;
  }

  .medium-margin-collapse > .large-5 {
    width: 41.6667%;
  }

  .medium-margin-collapse > .large-6 {
    width: 50%;
  }

  .medium-margin-collapse > .large-7 {
    width: 58.3333%;
  }

  .medium-margin-collapse > .large-8 {
    width: 66.6667%;
  }

  .medium-margin-collapse > .large-9 {
    width: 75%;
  }

  .medium-margin-collapse > .large-10 {
    width: 83.3333%;
  }

  .medium-margin-collapse > .large-11 {
    width: 91.6667%;
  }

  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 48em) {
  .medium-padding-collapse {
    margin-left: 0;
    margin-right: 0;
  }

  .medium-padding-collapse > .cell {
    padding-left: 0;
    padding-right: 0;
  }
}

@media print, screen and (min-width: 62em) {
  .large-margin-collapse, .large-margin-collapse > .cell {
    margin-left: 0;
    margin-right: 0;
  }

  .large-margin-collapse > .small-1 {
    width: 8.33333%;
  }

  .large-margin-collapse > .small-2 {
    width: 16.6667%;
  }

  .large-margin-collapse > .small-3 {
    width: 25%;
  }

  .large-margin-collapse > .small-4 {
    width: 33.3333%;
  }

  .large-margin-collapse > .small-5 {
    width: 41.6667%;
  }

  .large-margin-collapse > .small-6 {
    width: 50%;
  }

  .large-margin-collapse > .small-7 {
    width: 58.3333%;
  }

  .large-margin-collapse > .small-8 {
    width: 66.6667%;
  }

  .large-margin-collapse > .small-9 {
    width: 75%;
  }

  .large-margin-collapse > .small-10 {
    width: 83.3333%;
  }

  .large-margin-collapse > .small-11 {
    width: 91.6667%;
  }

  .large-margin-collapse > .small-12 {
    width: 100%;
  }

  .large-margin-collapse > .medium-1 {
    width: 8.33333%;
  }

  .large-margin-collapse > .medium-2 {
    width: 16.6667%;
  }

  .large-margin-collapse > .medium-3 {
    width: 25%;
  }

  .large-margin-collapse > .medium-4 {
    width: 33.3333%;
  }

  .large-margin-collapse > .medium-5 {
    width: 41.6667%;
  }

  .large-margin-collapse > .medium-6 {
    width: 50%;
  }

  .large-margin-collapse > .medium-7 {
    width: 58.3333%;
  }

  .large-margin-collapse > .medium-8 {
    width: 66.6667%;
  }

  .large-margin-collapse > .medium-9 {
    width: 75%;
  }

  .large-margin-collapse > .medium-10 {
    width: 83.3333%;
  }

  .large-margin-collapse > .medium-11 {
    width: 91.6667%;
  }

  .large-margin-collapse > .medium-12 {
    width: 100%;
  }

  .large-margin-collapse > .large-1 {
    width: 8.33333%;
  }

  .large-margin-collapse > .large-2 {
    width: 16.6667%;
  }

  .large-margin-collapse > .large-3 {
    width: 25%;
  }

  .large-margin-collapse > .large-4 {
    width: 33.3333%;
  }

  .large-margin-collapse > .large-5 {
    width: 41.6667%;
  }

  .large-margin-collapse > .large-6 {
    width: 50%;
  }

  .large-margin-collapse > .large-7 {
    width: 58.3333%;
  }

  .large-margin-collapse > .large-8 {
    width: 66.6667%;
  }

  .large-margin-collapse > .large-9 {
    width: 75%;
  }

  .large-margin-collapse > .large-10 {
    width: 83.3333%;
  }

  .large-margin-collapse > .large-11 {
    width: 91.6667%;
  }

  .large-margin-collapse > .large-12 {
    width: 100%;
  }

  .large-padding-collapse {
    margin-left: 0;
    margin-right: 0;
  }

  .large-padding-collapse > .cell {
    padding-left: 0;
    padding-right: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: .625rem;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + .625rem);
}

.small-offset-2 {
  margin-left: 16.6667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.6667% + .625rem);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + .625rem);
}

.small-offset-4 {
  margin-left: 33.3333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.3333% + .625rem);
}

.small-offset-5 {
  margin-left: 41.6667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.6667% + .625rem);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + .625rem);
}

.small-offset-7 {
  margin-left: 58.3333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.3333% + .625rem);
}

.small-offset-8 {
  margin-left: 66.6667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.6667% + .625rem);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + .625rem);
}

.small-offset-10 {
  margin-left: 83.3333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.3333% + .625rem);
}

.small-offset-11 {
  margin-left: 91.6667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.6667% + .625rem);
}

@media print, screen and (min-width: 48em) {
  .medium-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x > .medium-offset-0 {
    margin-left: .9375rem;
  }

  .medium-offset-1 {
    margin-left: 8.33333%;
  }

  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + .9375rem);
  }

  .medium-offset-2 {
    margin-left: 16.6667%;
  }

  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.6667% + .9375rem);
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + .9375rem);
  }

  .medium-offset-4 {
    margin-left: 33.3333%;
  }

  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.3333% + .9375rem);
  }

  .medium-offset-5 {
    margin-left: 41.6667%;
  }

  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.6667% + .9375rem);
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + .9375rem);
  }

  .medium-offset-7 {
    margin-left: 58.3333%;
  }

  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.3333% + .9375rem);
  }

  .medium-offset-8 {
    margin-left: 66.6667%;
  }

  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.6667% + .9375rem);
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + .9375rem);
  }

  .medium-offset-10 {
    margin-left: 83.3333%;
  }

  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.3333% + .9375rem);
  }

  .medium-offset-11 {
    margin-left: 91.6667%;
  }

  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.6667% + .9375rem);
  }
}

@media print, screen and (min-width: 62em) {
  .large-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x > .large-offset-0 {
    margin-left: .9375rem;
  }

  .large-offset-1 {
    margin-left: 8.33333%;
  }

  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + .9375rem);
  }

  .large-offset-2 {
    margin-left: 16.6667%;
  }

  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.6667% + .9375rem);
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + .9375rem);
  }

  .large-offset-4 {
    margin-left: 33.3333%;
  }

  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.3333% + .9375rem);
  }

  .large-offset-5 {
    margin-left: 41.6667%;
  }

  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.6667% + .9375rem);
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + .9375rem);
  }

  .large-offset-7 {
    margin-left: 58.3333%;
  }

  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.3333% + .9375rem);
  }

  .large-offset-8 {
    margin-left: 66.6667%;
  }

  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.6667% + .9375rem);
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + .9375rem);
  }

  .large-offset-10 {
    margin-left: 83.3333%;
  }

  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.3333% + .9375rem);
  }

  .large-offset-11 {
    margin-left: 91.6667%;
  }

  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.6667% + .9375rem);
  }
}

.grid-y {
  flex-flow: column;
  display: flex;
}

.grid-y > .cell {
  height: auto;
  max-height: none;
}

.grid-y > .auto, .grid-y > .shrink {
  height: auto;
}

.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 48em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 62em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}

.grid-y > .small-12, .grid-y > .small-11, .grid-y > .small-10, .grid-y > .small-9, .grid-y > .small-8, .grid-y > .small-7, .grid-y > .small-6, .grid-y > .small-5, .grid-y > .small-4, .grid-y > .small-3, .grid-y > .small-2, .grid-y > .small-1 {
  flex: none;
}

.grid-y > .small-1 {
  height: 8.33333%;
}

.grid-y > .small-2 {
  height: 16.6667%;
}

.grid-y > .small-3 {
  height: 25%;
}

.grid-y > .small-4 {
  height: 33.3333%;
}

.grid-y > .small-5 {
  height: 41.6667%;
}

.grid-y > .small-6 {
  height: 50%;
}

.grid-y > .small-7 {
  height: 58.3333%;
}

.grid-y > .small-8 {
  height: 66.6667%;
}

.grid-y > .small-9 {
  height: 75%;
}

.grid-y > .small-10 {
  height: 83.3333%;
}

.grid-y > .small-11 {
  height: 91.6667%;
}

.grid-y > .small-12 {
  height: 100%;
}

@media print, screen and (min-width: 48em) {
  .grid-y > .medium-auto {
    flex: 1 1 0;
    height: auto;
  }

  .grid-y > .medium-12, .grid-y > .medium-11, .grid-y > .medium-10, .grid-y > .medium-9, .grid-y > .medium-8, .grid-y > .medium-7, .grid-y > .medium-6, .grid-y > .medium-5, .grid-y > .medium-4, .grid-y > .medium-3, .grid-y > .medium-2, .grid-y > .medium-1, .grid-y > .medium-shrink {
    flex: none;
  }

  .grid-y > .medium-shrink {
    height: auto;
  }

  .grid-y > .medium-1 {
    height: 8.33333%;
  }

  .grid-y > .medium-2 {
    height: 16.6667%;
  }

  .grid-y > .medium-3 {
    height: 25%;
  }

  .grid-y > .medium-4 {
    height: 33.3333%;
  }

  .grid-y > .medium-5 {
    height: 41.6667%;
  }

  .grid-y > .medium-6 {
    height: 50%;
  }

  .grid-y > .medium-7 {
    height: 58.3333%;
  }

  .grid-y > .medium-8 {
    height: 66.6667%;
  }

  .grid-y > .medium-9 {
    height: 75%;
  }

  .grid-y > .medium-10 {
    height: 83.3333%;
  }

  .grid-y > .medium-11 {
    height: 91.6667%;
  }

  .grid-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 62em) {
  .grid-y > .large-auto {
    flex: 1 1 0;
    height: auto;
  }

  .grid-y > .large-12, .grid-y > .large-11, .grid-y > .large-10, .grid-y > .large-9, .grid-y > .large-8, .grid-y > .large-7, .grid-y > .large-6, .grid-y > .large-5, .grid-y > .large-4, .grid-y > .large-3, .grid-y > .large-2, .grid-y > .large-1, .grid-y > .large-shrink {
    flex: none;
  }

  .grid-y > .large-shrink {
    height: auto;
  }

  .grid-y > .large-1 {
    height: 8.33333%;
  }

  .grid-y > .large-2 {
    height: 16.6667%;
  }

  .grid-y > .large-3 {
    height: 25%;
  }

  .grid-y > .large-4 {
    height: 33.3333%;
  }

  .grid-y > .large-5 {
    height: 41.6667%;
  }

  .grid-y > .large-6 {
    height: 50%;
  }

  .grid-y > .large-7 {
    height: 58.3333%;
  }

  .grid-y > .large-8 {
    height: 66.6667%;
  }

  .grid-y > .large-9 {
    height: 75%;
  }

  .grid-y > .large-10 {
    height: 83.3333%;
  }

  .grid-y > .large-11 {
    height: 91.6667%;
  }

  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -.9375rem;
    margin-bottom: -.9375rem;
  }
}

.grid-padding-y > .cell {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-padding-y > .cell {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y {
    margin-top: -.9375rem;
    margin-bottom: -.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }
}

.grid-margin-y > .auto, .grid-margin-y > .shrink {
  height: auto;
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y > .auto, .grid-margin-y > .shrink {
    height: auto;
  }

  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y > .small-2 {
    height: calc(16.6667% - 1.875rem);
  }

  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y > .small-4 {
    height: calc(33.3333% - 1.875rem);
  }

  .grid-margin-y > .small-5 {
    height: calc(41.6667% - 1.875rem);
  }

  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y > .small-7 {
    height: calc(58.3333% - 1.875rem);
  }

  .grid-margin-y > .small-8 {
    height: calc(66.6667% - 1.875rem);
  }

  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y > .small-10 {
    height: calc(83.3333% - 1.875rem);
  }

  .grid-margin-y > .small-11 {
    height: calc(91.6667% - 1.875rem);
  }

  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }

  .grid-margin-y > .medium-auto, .grid-margin-y > .medium-shrink {
    height: auto;
  }

  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y > .medium-2 {
    height: calc(16.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y > .medium-4 {
    height: calc(33.3333% - 1.875rem);
  }

  .grid-margin-y > .medium-5 {
    height: calc(41.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y > .medium-7 {
    height: calc(58.3333% - 1.875rem);
  }

  .grid-margin-y > .medium-8 {
    height: calc(66.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y > .medium-10 {
    height: calc(83.3333% - 1.875rem);
  }

  .grid-margin-y > .medium-11 {
    height: calc(91.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 62em) {
  .grid-margin-y > .large-auto, .grid-margin-y > .large-shrink {
    height: auto;
  }

  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y > .large-2 {
    height: calc(16.6667% - 1.875rem);
  }

  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y > .large-4 {
    height: calc(33.3333% - 1.875rem);
  }

  .grid-margin-y > .large-5 {
    height: calc(41.6667% - 1.875rem);
  }

  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y > .large-7 {
    height: calc(58.3333% - 1.875rem);
  }

  .grid-margin-y > .large-8 {
    height: calc(66.6667% - 1.875rem);
  }

  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y > .large-10 {
    height: calc(83.3333% - 1.875rem);
  }

  .grid-margin-y > .large-11 {
    height: calc(91.6667% - 1.875rem);
  }

  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame {
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  max-width: 100%;
  overflow-x: auto;
}

.cell-block-y {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.cell-block-container {
  flex-direction: column;
  max-height: 100%;
  display: flex;
}

.cell-block-container > .grid-x {
  flex-wrap: nowrap;
  max-height: 100%;
}

@media print, screen and (min-width: 48em) {
  .medium-grid-frame {
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
    position: relative;
    overflow: hidden;
  }

  .cell .medium-grid-frame {
    width: 100%;
  }

  .medium-cell-block {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    max-width: 100%;
    overflow-x: auto;
  }

  .medium-cell-block-container {
    flex-direction: column;
    max-height: 100%;
    display: flex;
  }

  .medium-cell-block-container > .grid-x {
    flex-wrap: nowrap;
    max-height: 100%;
  }

  .medium-cell-block-y {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

@media print, screen and (min-width: 62em) {
  .large-grid-frame {
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
    position: relative;
    overflow: hidden;
  }

  .cell .large-grid-frame {
    width: 100%;
  }

  .large-cell-block {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    max-width: 100%;
    overflow-x: auto;
  }

  .large-cell-block-container {
    flex-direction: column;
    max-height: 100%;
    display: flex;
  }

  .large-cell-block-container > .grid-x {
    flex-wrap: nowrap;
    max-height: 100%;
  }

  .large-cell-block-y {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

.grid-y.grid-frame {
  flex-wrap: nowrap;
  align-items: stretch;
  width: auto;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@media print, screen and (min-width: 48em) {
  .grid-y.medium-grid-frame {
    flex-wrap: nowrap;
    align-items: stretch;
    width: auto;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
}

@media print, screen and (min-width: 62em) {
  .grid-y.large-grid-frame {
    flex-wrap: nowrap;
    align-items: stretch;
    width: auto;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}

@media print, screen and (min-width: 48em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 62em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y {
    margin-top: -.9375rem;
    margin-bottom: -.9375rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: .625rem;
  margin-bottom: .625rem;
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }
}

.grid-margin-y > .auto, .grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 1.25rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.6667% - 1.25rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.3333% - 1.25rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.6667% - 1.25rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.3333% - 1.25rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.6667% - 1.25rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.3333% - 1.25rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.6667% - 1.25rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y > .auto, .grid-margin-y > .shrink {
    height: auto;
  }

  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y > .small-2 {
    height: calc(16.6667% - 1.875rem);
  }

  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y > .small-4 {
    height: calc(33.3333% - 1.875rem);
  }

  .grid-margin-y > .small-5 {
    height: calc(41.6667% - 1.875rem);
  }

  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y > .small-7 {
    height: calc(58.3333% - 1.875rem);
  }

  .grid-margin-y > .small-8 {
    height: calc(66.6667% - 1.875rem);
  }

  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y > .small-10 {
    height: calc(83.3333% - 1.875rem);
  }

  .grid-margin-y > .small-11 {
    height: calc(91.6667% - 1.875rem);
  }

  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }

  .grid-margin-y > .medium-auto, .grid-margin-y > .medium-shrink {
    height: auto;
  }

  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y > .medium-2 {
    height: calc(16.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y > .medium-4 {
    height: calc(33.3333% - 1.875rem);
  }

  .grid-margin-y > .medium-5 {
    height: calc(41.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y > .medium-7 {
    height: calc(58.3333% - 1.875rem);
  }

  .grid-margin-y > .medium-8 {
    height: calc(66.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y > .medium-10 {
    height: calc(83.3333% - 1.875rem);
  }

  .grid-margin-y > .medium-11 {
    height: calc(91.6667% - 1.875rem);
  }

  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 62em) {
  .grid-margin-y > .large-auto, .grid-margin-y > .large-shrink {
    height: auto;
  }

  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y > .large-2 {
    height: calc(16.6667% - 1.875rem);
  }

  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y > .large-4 {
    height: calc(33.3333% - 1.875rem);
  }

  .grid-margin-y > .large-5 {
    height: calc(41.6667% - 1.875rem);
  }

  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y > .large-7 {
    height: calc(58.3333% - 1.875rem);
  }

  .grid-margin-y > .large-8 {
    height: calc(66.6667% - 1.875rem);
  }

  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y > .large-10 {
    height: calc(83.3333% - 1.875rem);
  }

  .grid-margin-y > .large-11 {
    height: calc(91.6667% - 1.875rem);
  }

  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem);
}

@media print, screen and (min-width: 48em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 62em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 48em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 62em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}

.button {
  vertical-align: middle;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 0;
  margin: 0 0 1rem;
  padding: .85em 1em;
  font-family: inherit;
  font-size: .9rem;
  line-height: 1;
  transition: background-color .25s ease-out, color .25s ease-out;
  display: inline-block;
}

[data-whatinput="mouse"] .button {
  outline: 0;
}

.button.tiny {
  font-size: .6rem;
}

.button.small {
  font-size: .75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: block;
}

.button, .button.disabled, .button[disabled], .button.disabled:hover, .button[disabled]:hover, .button.disabled:focus, .button[disabled]:focus {
  color: #fefefe;
  background-color: #cc2e3d;
}

.button:hover, .button:focus {
  color: #fefefe;
  background-color: #ad2734;
}

.button.primary, .button.primary.disabled, .button.primary[disabled], .button.primary.disabled:hover, .button.primary[disabled]:hover, .button.primary.disabled:focus, .button.primary[disabled]:focus {
  color: #fefefe;
  background-color: #cc2e3d;
}

.button.primary:hover, .button.primary:focus {
  color: #fefefe;
  background-color: #a32531;
}

.button.secondary, .button.secondary.disabled, .button.secondary[disabled], .button.secondary.disabled:hover, .button.secondary[disabled]:hover, .button.secondary.disabled:focus, .button.secondary[disabled]:focus {
  color: #fefefe;
  background-color: #65767d;
}

.button.secondary:hover, .button.secondary:focus {
  color: #fefefe;
  background-color: #515e64;
}

.button.subtle, .button.subtle.disabled, .button.subtle[disabled], .button.subtle.disabled:hover, .button.subtle[disabled]:hover, .button.subtle.disabled:focus, .button.subtle[disabled]:focus {
  color: #0a0a0a;
  background-color: #e5e5e5;
}

.button.subtle:hover, .button.subtle:focus {
  color: #0a0a0a;
  background-color: #b7b7b7;
}

.button.success, .button.success.disabled, .button.success[disabled], .button.success.disabled:hover, .button.success[disabled]:hover, .button.success.disabled:focus, .button.success[disabled]:focus {
  color: #0a0a0a;
  background-color: #3adb76;
}

.button.success:hover, .button.success:focus {
  color: #0a0a0a;
  background-color: #22bb5b;
}

.button.warning, .button.warning.disabled, .button.warning[disabled], .button.warning.disabled:hover, .button.warning[disabled]:hover, .button.warning.disabled:focus, .button.warning[disabled]:focus {
  color: #0a0a0a;
  background-color: #ffae00;
}

.button.warning:hover, .button.warning:focus {
  color: #0a0a0a;
  background-color: #cc8b00;
}

.button.alert, .button.alert.disabled, .button.alert[disabled], .button.alert.disabled:hover, .button.alert[disabled]:hover, .button.alert.disabled:focus, .button.alert[disabled]:focus {
  color: #fefefe;
  background-color: #cc2e3d;
}

.button.alert:hover, .button.alert:focus {
  color: #fefefe;
  background-color: #a32531;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus, .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: #0000;
}

.button.hollow, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover, .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
  color: #cc2e3d;
  border: 1px solid #cc2e3d;
}

.button.hollow:hover, .button.hollow:focus {
  color: #66171f;
  border-color: #66171f;
}

.button.hollow.primary, .button.hollow.primary.disabled, .button.hollow.primary[disabled], .button.hollow.primary.disabled:hover, .button.hollow.primary[disabled]:hover, .button.hollow.primary.disabled:focus, .button.hollow.primary[disabled]:focus {
  color: #cc2e3d;
  border: 1px solid #cc2e3d;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  color: #66171f;
  border-color: #66171f;
}

.button.hollow.secondary, .button.hollow.secondary.disabled, .button.hollow.secondary[disabled], .button.hollow.secondary.disabled:hover, .button.hollow.secondary[disabled]:hover, .button.hollow.secondary.disabled:focus, .button.hollow.secondary[disabled]:focus {
  color: #65767d;
  border: 1px solid #65767d;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  color: #333b3f;
  border-color: #333b3f;
}

.button.hollow.subtle, .button.hollow.subtle.disabled, .button.hollow.subtle[disabled], .button.hollow.subtle.disabled:hover, .button.hollow.subtle[disabled]:hover, .button.hollow.subtle.disabled:focus, .button.hollow.subtle[disabled]:focus {
  color: #e5e5e5;
  border: 1px solid #e5e5e5;
}

.button.hollow.subtle:hover, .button.hollow.subtle:focus {
  color: #737373;
  border-color: #737373;
}

.button.hollow.success, .button.hollow.success.disabled, .button.hollow.success[disabled], .button.hollow.success.disabled:hover, .button.hollow.success[disabled]:hover, .button.hollow.success.disabled:focus, .button.hollow.success[disabled]:focus {
  color: #3adb76;
  border: 1px solid #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  color: #157539;
  border-color: #157539;
}

.button.hollow.warning, .button.hollow.warning.disabled, .button.hollow.warning[disabled], .button.hollow.warning.disabled:hover, .button.hollow.warning[disabled]:hover, .button.hollow.warning.disabled:focus, .button.hollow.warning[disabled]:focus {
  color: #ffae00;
  border: 1px solid #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  color: #805700;
  border-color: #805700;
}

.button.hollow.alert, .button.hollow.alert.disabled, .button.hollow.alert[disabled], .button.hollow.alert.disabled:hover, .button.hollow.alert[disabled]:hover, .button.hollow.alert.disabled:focus, .button.hollow.alert[disabled]:focus {
  color: #cc2e3d;
  border: 1px solid #cc2e3d;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  color: #66171f;
  border-color: #66171f;
}

.button.clear, .button.clear:hover, .button.clear:focus, .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: #0000;
  border-color: #0000;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear.disabled:hover, .button.clear[disabled]:hover, .button.clear.disabled:focus, .button.clear[disabled]:focus {
  color: #cc2e3d;
}

.button.clear:hover, .button.clear:focus {
  color: #66171f;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary.disabled:hover, .button.clear.primary[disabled]:hover, .button.clear.primary.disabled:focus, .button.clear.primary[disabled]:focus {
  color: #cc2e3d;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  color: #66171f;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary.disabled:hover, .button.clear.secondary[disabled]:hover, .button.clear.secondary.disabled:focus, .button.clear.secondary[disabled]:focus {
  color: #65767d;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  color: #333b3f;
}

.button.clear.subtle, .button.clear.subtle.disabled, .button.clear.subtle[disabled], .button.clear.subtle.disabled:hover, .button.clear.subtle[disabled]:hover, .button.clear.subtle.disabled:focus, .button.clear.subtle[disabled]:focus {
  color: #e5e5e5;
}

.button.clear.subtle:hover, .button.clear.subtle:focus {
  color: #737373;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success.disabled:hover, .button.clear.success[disabled]:hover, .button.clear.success.disabled:focus, .button.clear.success[disabled]:focus {
  color: #3adb76;
}

.button.clear.success:hover, .button.clear.success:focus {
  color: #157539;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning.disabled:hover, .button.clear.warning[disabled]:hover, .button.clear.warning.disabled:focus, .button.clear.warning[disabled]:focus {
  color: #ffae00;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  color: #805700;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert.disabled:hover, .button.clear.alert[disabled]:hover, .button.clear.alert.disabled:focus, .button.clear.alert[disabled]:focus {
  color: #cc2e3d;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  color: #66171f;
}

.button.disabled, .button[disabled] {
  opacity: .25;
  cursor: not-allowed;
}

.button.dropdown:after {
  content: "";
  float: right;
  border: .4em solid #0000;
  border-top-color: #fefefe;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-left: 1em;
  display: inline-block;
  position: relative;
  top: .4em;
}

.button.dropdown.hollow:after, .button.dropdown.clear:after, .button.dropdown.hollow.primary:after, .button.dropdown.clear.primary:after {
  border-top-color: #cc2e3d;
}

.button.dropdown.hollow.secondary:after, .button.dropdown.clear.secondary:after {
  border-top-color: #65767d;
}

.button.dropdown.hollow.subtle:after, .button.dropdown.clear.subtle:after {
  border-top-color: #e5e5e5;
}

.button.dropdown.hollow.success:after, .button.dropdown.clear.success:after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning:after, .button.dropdown.clear.warning:after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert:after, .button.dropdown.clear.alert:after {
  border-top-color: #cc2e3d;
}

.button.arrow-only:after {
  float: none;
  margin-left: 0;
  top: -.1em;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.label {
  white-space: nowrap;
  cursor: default;
  color: #fefefe;
  background: #cc2e3d;
  border-radius: 2pt;
  padding: .233333rem .5rem .333333rem;
  font-size: .8rem;
  line-height: 1;
  display: inline-block;
}

.label.primary {
  color: #fefefe;
  background: #cc2e3d;
}

.label.secondary {
  color: #fefefe;
  background: #65767d;
}

.label.subtle {
  color: #0a0a0a;
  background: #e5e5e5;
}

.label.success {
  color: #0a0a0a;
  background: #3adb76;
}

.label.warning {
  color: #0a0a0a;
  background: #ffae00;
}

.label.alert {
  color: #fefefe;
  background: #cc2e3d;
}

table {
  border-collapse: collapse;
  border-radius: 0;
  width: 100%;
  margin-bottom: 1rem;
}

thead, tbody, tfoot {
  background-color: #fefefe;
  border: none;
}

caption {
  padding: .5rem .625rem .625rem;
  font-weight: 900;
}

thead {
  color: #0a0a0a;
  background: #f8f8f8;
}

tfoot {
  color: #0a0a0a;
  background: #f1f1f1;
}

thead tr, tfoot tr {
  background: none;
}

thead th, thead td, tfoot th, tfoot td {
  text-align: left;
  padding: .5rem .625rem .625rem;
  font-weight: 900;
}

tbody th, tbody td {
  padding: .5rem .625rem .625rem;
}

tbody tr:nth-child(2n) {
  background-color: #f1f1f1;
  border-bottom: 0;
}

table.unstriped tbody {
  background-color: #fefefe;
}

table.unstriped tbody tr {
  background-color: #fefefe;
  border-bottom: none;
}

@media print, screen and (max-width: 61.9988em) {
  table.stack thead, table.stack tfoot {
    display: none;
  }

  table.stack tr, table.stack th, table.stack td {
    display: block;
  }

  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  width: 100%;
  display: block;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f3f3f3;
}

table.hover tfoot tr:hover {
  background-color: #ececec;
}

table.hover tbody tr:hover {
  background-color: #f9f9f9;
}

table.hover:not(.unstriped) tr:nth-of-type(2n):hover {
  background-color: #ececec;
}

.table-scroll {
  overflow-x: auto;
}

.badge {
  text-align: center;
  color: #fefefe;
  background: #cc2e3d;
  border-radius: 50%;
  min-width: 2.1em;
  padding: .3em;
  font-size: .6rem;
  display: inline-block;
}

.badge.primary {
  color: #fefefe;
  background: #cc2e3d;
}

.badge.secondary {
  color: #fefefe;
  background: #65767d;
}

.badge.subtle {
  color: #0a0a0a;
  background: #e5e5e5;
}

.badge.success {
  color: #0a0a0a;
  background: #3adb76;
}

.badge.warning {
  color: #0a0a0a;
  background: #ffae00;
}

.badge.alert {
  color: #fefefe;
  background: #cc2e3d;
}

.callout {
  color: #0a0a0a;
  background-color: #fefefe;
  border: none;
  border-radius: 2px;
  margin: 0 auto 1rem;
  padding: 1rem;
  position: relative;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  color: #fff;
  background-color: #cc2e3d;
}

.callout.secondary {
  color: #fff;
  background-color: #65767d;
}

.callout.subtle {
  color: #0a0a0a;
  background-color: #e5e5e5;
}

.callout.success {
  color: #0a0a0a;
  background-color: #3adb76;
}

.callout.warning {
  color: #0a0a0a;
  background-color: #ffae00;
}

.callout.alert {
  color: #fff;
  background-color: #cc2e3d;
}

.callout.small {
  padding: .5rem;
}

.callout.large {
  padding: 3rem;
}

.card {
  color: #0a0a0a;
  background: #f6f6f6;
  border: none;
  border-radius: 2pt;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 .333333rem #0003;
}

.card > :last-child {
  margin-bottom: 0;
}

.card-divider {
  background: #e6e6e6;
  flex: 0 auto;
  padding: 1rem;
  display: flex;
}

.card-divider > :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}

.card-section > :last-child {
  margin-bottom: 0;
}

.card-image {
  min-height: 1px;
}

.pagination {
  margin-bottom: 1rem;
  margin-left: 0;
}

.pagination:before, .pagination:after {
  content: " ";
  flex-basis: 0;
  order: 1;
  display: table;
}

.pagination:after {
  clear: both;
}

.pagination li {
  border-radius: 0;
  margin-right: .0625rem;
  font-size: .875rem;
  display: none;
}

.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}

@media print, screen and (min-width: 48em) {
  .pagination li {
    display: inline-block;
  }
}

.pagination a, .pagination button {
  color: #0a0a0a;
  border-radius: 0;
  padding: .1875rem .625rem;
  display: block;
}

.pagination a:hover, .pagination button:hover {
  background: #e6e6e6;
}

.pagination .current {
  color: #fefefe;
  cursor: default;
  background: #cc2e3d;
  padding: .1875rem .625rem;
}

.pagination .disabled {
  color: #cacaca;
  cursor: not-allowed;
  padding: .1875rem .625rem;
}

.pagination .disabled:hover {
  background: none;
}

.pagination .ellipsis:after {
  content: "…";
  color: #0a0a0a;
  padding: .1875rem .625rem;
}

.pagination-previous a:before, .pagination-previous.disabled:before {
  content: "«";
  margin-right: .5rem;
  display: inline-block;
}

.pagination-next a:after, .pagination-next.disabled:after {
  content: "»";
  margin-left: .5rem;
  display: inline-block;
}

.accordion {
  background: #fefefe;
  margin-left: 0;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child, .accordion-item:last-child > :last-child {
  border-radius: 0;
}

.accordion-title {
  font-size: inherit;
  color: inherit;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  padding: 1.25rem 1rem;
  line-height: 1;
  display: block;
  position: relative;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title:before {
  content: "+";
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
  right: 1rem;
}

.is-active > .accordion-title:before {
  content: "–";
}

.accordion-content {
  color: #0a0a0a;
  background-color: #fefefe;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  padding: 1rem;
  display: none;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.responsive-embed, .flex-video {
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}

.responsive-embed iframe, .responsive-embed object, .responsive-embed embed, .responsive-embed video, .flex-video iframe, .flex-video object, .flex-video embed, .flex-video video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.responsive-embed.widescreen, .flex-video.widescreen {
  padding-bottom: 56.25%;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-left.vertical.menu > li > a {
  justify-content: flex-start;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  place-content: center;
  align-items: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 48em) {
  .medium-order-1 {
    order: 1;
  }

  .medium-order-2 {
    order: 2;
  }

  .medium-order-3 {
    order: 3;
  }

  .medium-order-4 {
    order: 4;
  }

  .medium-order-5 {
    order: 5;
  }

  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 62em) {
  .large-order-1 {
    order: 1;
  }

  .large-order-2 {
    order: 2;
  }

  .large-order-3 {
    order: 3;
  }

  .large-order-4 {
    order: 4;
  }

  .large-order-5 {
    order: 5;
  }

  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 48em) {
  .medium-flex-container {
    display: flex;
  }

  .medium-flex-child-auto {
    flex: auto;
  }

  .medium-flex-child-grow {
    flex: 1 0 auto;
  }

  .medium-flex-child-shrink {
    flex: 0 auto;
  }

  .medium-flex-dir-row {
    flex-direction: row;
  }

  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }

  .medium-flex-dir-column {
    flex-direction: column;
  }

  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 62em) {
  .large-flex-container {
    display: flex;
  }

  .large-flex-child-auto {
    flex: auto;
  }

  .large-flex-child-grow {
    flex: 1 0 auto;
  }

  .large-flex-child-shrink {
    flex: 0 auto;
  }

  .large-flex-dir-row {
    flex-direction: row;
  }

  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }

  .large-flex-dir-column {
    flex-direction: column;
  }

  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

@media print, screen and (max-width: 47.9988em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 48em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 47.9988em) {
  .show-for-medium {
    display: none !important;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 61.9988em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 47.9988em), screen and (min-width: 62em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 62em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 61.9988em) {
  .show-for-large {
    display: none !important;
  }
}

@media print, screen and (min-width: 62em) and (max-width: 74.9988em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 61.9988em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr, .show-on-focus {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.show-on-focus:active, .show-on-focus:focus {
  clip: auto !important;
  white-space: normal !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
  overflow: visible !important;
}

.show-for-landscape, .hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape, .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape, .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape, .show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape, .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape, .show-for-portrait {
    display: block !important;
  }
}

.show-for-dark-mode {
  display: none;
}

.hide-for-dark-mode {
  display: block;
}

@media screen and (prefers-color-scheme: dark) {
  .show-for-dark-mode {
    display: block !important;
  }

  .hide-for-dark-mode {
    display: none !important;
  }
}

.show-for-ie {
  display: none;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .show-for-ie {
    display: block !important;
  }

  .hide-for-ie {
    display: none !important;
  }
}

.show-for-sticky {
  display: none;
}

.is-stuck .show-for-sticky {
  display: block;
}

.is-stuck .hide-for-sticky {
  display: none;
}

cite {
  font-size: inherit;
  color: inherit;
  font-style: italic;
  display: inline;
}

cite:before {
  content: none;
}

blockquote cite {
  color: inherit;
  font-size: .8125rem;
  display: block;
}

blockquote cite:before {
  content: "— ";
}

label {
  margin: unset;
}

.label {
  vertical-align: .1rem;
}

.pagination button {
  cursor: pointer;
  line-height: inherit;
}

.small-lead {
  font-size: 125%;
  line-height: 1.6;
}

@media print, screen and (min-width: 48em) {
  .medium-lead {
    font-size: 125%;
    line-height: 1.6;
  }
}

@media print, screen and (min-width: 62em) {
  .large-lead {
    font-size: 125%;
    line-height: 1.6;
  }
}

.text-secondary {
  color: #65767d;
}

.text-primary {
  color: #cc2e3d;
}

.icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-line: underline;
  text-decoration-color: #0a0a0a33;
  transition-property: color, text-decoration-color;
  transition-duration: .1s;
  transition-timing-function: ease-out;
}

.icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #007491;
  text-decoration-color: #00749180;
  transition-duration: 50ms;
}

.icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #512091;
  text-decoration-color: #51209180;
}

.text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

.callout.primary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.primary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.primary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.primary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.primary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.primary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.callout.primary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.primary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.primary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.primary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.primary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.primary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.primary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.primary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.primary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.primary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.primary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.primary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.callout.primary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.primary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.primary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.primary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.primary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.primary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.primary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.primary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.primary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.primary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.primary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.primary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

.callout.secondary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.secondary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.secondary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.secondary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.secondary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.secondary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.callout.secondary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.secondary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.secondary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.secondary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.secondary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.secondary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.secondary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.secondary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.secondary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.secondary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.secondary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.secondary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.callout.secondary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.secondary .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.secondary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.secondary .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.secondary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.secondary p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.secondary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.secondary .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.secondary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.secondary ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.secondary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.secondary ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

.callout.subtle .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.subtle .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.subtle p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.subtle .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.subtle ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.subtle ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.callout.subtle .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.subtle .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.subtle .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.subtle .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.subtle p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.subtle p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.subtle .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.subtle .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.subtle ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.subtle ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.subtle ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.subtle ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.callout.subtle .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.subtle .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.subtle .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.subtle .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.subtle p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.subtle p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.subtle .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.subtle .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.subtle ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.subtle ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.subtle ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.subtle ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

.callout.success .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.success .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.success p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.success .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.success ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.success ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.callout.success .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.success .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.success .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.success .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.success p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.success p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.success .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.success .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.success ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.success ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.success ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.success ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.callout.success .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.success .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.success .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.success .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.success p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.success p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.success .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.success .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.success ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.success ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.success ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.success ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

.callout.warning .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.warning .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.warning p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.warning .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.warning ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.warning ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.callout.warning .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.warning .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.warning .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.warning .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.warning p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.warning p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.warning .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.warning .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.warning ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.warning ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.warning ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.warning ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.callout.warning .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.warning .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.warning .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.warning .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.warning p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.warning p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.warning .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.warning .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.warning ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.warning ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.warning ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.warning ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

.callout.alert .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.alert .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.alert p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.alert .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.alert ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.alert ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  text-decoration-color: #fff5;
}

.callout.alert .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.alert .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.alert .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.alert .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.alert p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.alert p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.alert .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.alert .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.alert ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.alert ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .callout.alert ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .callout.alert ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
  color: #00aeda;
  text-decoration-color: #00aeda80;
}

.callout.alert .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.alert .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.alert .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.alert .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.alert p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.alert p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.alert .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.alert .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.alert ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.alert ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .callout.alert ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .callout.alert ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
  color: #a577e1;
  text-decoration-color: #a577e180;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .medium-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .medium-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .medium-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .medium-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .medium-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
    text-decoration-color: #fff5;
  }

  .medium-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .medium-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .medium-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .medium-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .medium-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .medium-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .medium-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .medium-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .medium-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .medium-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .medium-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .medium-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
    color: #00aeda;
    text-decoration-color: #00aeda80;
  }

  .medium-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .medium-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .medium-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .medium-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .medium-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .medium-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .medium-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .medium-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .medium-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .medium-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .medium-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .medium-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
    color: #a577e1;
    text-decoration-color: #a577e180;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .large-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .large-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .large-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .large-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .large-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
    text-decoration-color: #fff5;
  }

  .large-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .large-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .large-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .large-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .large-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .large-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .large-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .large-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .large-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .large-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover, .large-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):focus, .large-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):hover {
    color: #00aeda;
    text-decoration-color: #00aeda80;
  }

  .large-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .large-text-dark .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .large-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .large-text-dark .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .large-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .large-text-dark p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .large-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .large-text-dark .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .large-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .large-text-dark ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover, .large-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:focus, .large-text-dark ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable):visited:hover {
    color: #a577e1;
    text-decoration-color: #a577e180;
  }
}

.callout.health-check .icon-grid-label a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.health-check .inline-content a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.health-check p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.health-check .p a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.health-check ol a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable), .callout.health-check ul:not(.pagination) a:not(.accordion-title):not(.block):not(.button):not(.non-discoverable) {
  color: #fff;
  text-decoration-color: #fff5;
}

a.non-discoverable {
  cursor: default;
}

.text-dark abbr, .callout.primary abbr, .callout.secondary abbr, .callout.subtle abbr, .callout.success abbr, .callout.warning abbr, .callout.alert abbr {
  border-bottom-color: #fff;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark abbr {
    border-bottom-color: #fff;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark abbr {
    border-bottom-color: #fff;
  }
}

.article-body:not(.continued) > p:not(.no-drop-cap):first-of-type:first-letter {
  float: left;
  margin-bottom: -.3em;
  margin-right: .1em;
  font-size: 3.2em;
  font-weight: 300;
  line-height: 1;
}

.article-content {
  font-family: Georgia, Cambria, Times New Roman, Times, serif;
}

@media print, screen and (min-width: 48em) {
  .article-content {
    font-size: 1.16667rem;
  }
}

.article-head {
  text-align: center;
  margin-bottom: 3em;
}

.article-head .phrasing-block {
  margin-left: auto;
  margin-right: auto;
}

blockquote {
  padding-left: 3rem;
  position: relative;
  overflow: hidden;
}

blockquote:before {
  content: "“";
  opacity: .125;
  font-family: Arial, sans-serif;
  font-size: 8rem;
  font-weight: 900;
  position: absolute;
  top: -3rem;
  left: 0;
}

.caps {
  letter-spacing: .166667ch;
  text-transform: uppercase;
  font-size: .8em;
  font-weight: 900;
}

.article-content .card {
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: normal;
}

.columns-2 {
  columns: 2;
  column-gap: calc(1rem + 1.5em);
}

@media print, screen and (min-width: 48em) {
  .medium-columns-2 {
    columns: 2;
    column-gap: calc(1rem + 1.5em);
  }
}

@media print, screen and (min-width: 62em) {
  .large-columns-2 {
    columns: 2;
    column-gap: calc(1rem + 1.5em);
  }
}

.columns-3 {
  columns: 3;
  column-gap: calc(1rem + 1.5em);
}

@media print, screen and (min-width: 48em) {
  .medium-columns-3 {
    columns: 3;
    column-gap: calc(1rem + 1.5em);
  }
}

@media print, screen and (min-width: 62em) {
  .large-columns-3 {
    columns: 3;
    column-gap: calc(1rem + 1.5em);
  }
}

.field-label {
  letter-spacing: .166667ch;
  text-transform: uppercase;
  color: #525252;
  margin-bottom: .5rem;
  font-size: .8em;
  font-weight: 900;
  display: block;
}

.text-dark .field-label, .callout.primary .field-label, .callout.secondary .field-label, .callout.subtle .field-label, .callout.success .field-label, .callout.warning .field-label, .callout.alert .field-label {
  color: #ffffffc4;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .field-label {
    color: #ffffffc4;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .field-label {
    color: #ffffffc4;
  }
}

.field-label + input[type="email"].hollow, .field-label + input[type="tel"].hollow, .field-label + input[type="text"].hollow, .field-label + textarea.hollow {
  margin-top: -.5rem;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
}

.article-content h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p), .article-content .h1 {
  font-family: Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 1.75rem;
  font-weight: 300;
}

@media print, screen and (min-width: 48em) {
  .article-content h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p), .article-content .h1 {
    font-size: 2.5rem;
  }
}

h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p), .h1, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p), .h2 {
  position: relative;
}

@media print, screen and (min-width: 48em) {
  .article-content h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p), .article-content .h1, .article-content h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p), .article-content .h2 {
    margin-top: 1em;
  }
}

h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain), .h1:not(.plain), h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain), .h2:not(.plain) {
  clear: left;
  padding-top: .333333rem;
  padding-bottom: .333333rem;
}

h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .h1:not(.plain):before, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .h2:not(.plain):before {
  content: "";
  background-color: #0a0a0a;
  width: .333333rem;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.666667rem;
}

.text-dark h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .text-dark .h1:not(.plain):before, .text-dark h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .text-dark .h2:not(.plain):before, .callout.primary h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.primary .h1:not(.plain):before, .callout.primary h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.primary .h2:not(.plain):before, .callout.secondary h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.secondary .h1:not(.plain):before, .callout.secondary h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.secondary .h2:not(.plain):before, .callout.subtle h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.subtle .h1:not(.plain):before, .callout.subtle h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.subtle .h2:not(.plain):before, .callout.success h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.success .h1:not(.plain):before, .callout.success h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.success .h2:not(.plain):before, .callout.warning h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.warning .h1:not(.plain):before, .callout.warning h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.warning .h2:not(.plain):before, .callout.alert h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.alert .h1:not(.plain):before, .callout.alert h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .callout.alert .h2:not(.plain):before {
  background-color: #fff;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .medium-text-dark .h1:not(.plain):before, .medium-text-dark h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .medium-text-dark .h2:not(.plain):before {
    background-color: #fff;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .large-text-dark .h1:not(.plain):before, .large-text-dark h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .large-text-dark .h2:not(.plain):before {
    background-color: #fff;
  }
}

@media print, screen and (min-width: 48em) {
  h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .h1:not(.plain):before, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .h2:not(.plain):before {
    width: .5rem;
    left: -1rem;
  }
}

@media screen and (min-width: 75em) {
  h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .h1:not(.plain):before, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before, .h2:not(.plain):before {
    left: -1.5rem;
  }
}

h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .caps, .h1 .caps, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .caps, .h2 .caps {
  font-size: .9rem;
}

@media print, screen and (min-width: 48em) {
  h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .caps, .h1 .caps, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .caps, .h2 .caps {
    font-size: .888889ex;
  }

  .article-content h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .caps, .article-content .h1 .caps, .article-content h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .caps, .article-content .h2 .caps {
    font-size: .666667ex;
  }
}

h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .phrasing-block, .h1 .phrasing-block, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .phrasing-block, .h2 .phrasing-block {
  margin-bottom: .333333em;
  display: block;
}

h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .subtitle, .h1 .subtitle, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .subtitle, .h2 .subtitle {
  margin-top: .333333em;
  font-size: 1rem;
}

@media print, screen and (min-width: 48em) {
  h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .subtitle, .h1 .subtitle, h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) .subtitle, .h2 .subtitle {
    font-size: 1.16667rem;
  }
}

h4:not(.h1):not(.h2):not(.h3):not(.h5):not(.h6):not(.p), .h4 {
  letter-spacing: .166667ch;
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: 900;
}

@media print, screen and (min-width: 48em) {
  h4:not(.h1):not(.h2):not(.h3):not(.h5):not(.h6):not(.p), .h4 {
    letter-spacing: .166667ch;
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: 900;
  }
}

h6:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.p), .h6 {
  opacity: .8;
}

.inline-list {
  margin-left: 0;
  list-style: none;
  display: inline;
}

.inline-list > li {
  display: inline;
}

.no-margin-bottom {
  margin-bottom: 0;
}

@media print, screen and (min-width: 48em) {
  .medium-no-margin-bottom {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 62em) {
  .large-no-margin-bottom {
    margin-bottom: 0;
  }
}

address, figure, .p, .p-margin, p {
  margin-bottom: 1rem;
  display: block;
}

.article-content address, .article-content figure, .article-content .p, .article-content .p-margin, .article-content p {
  margin-bottom: 1em;
}

.p-margin {
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 48em) {
  .medium-p-margin {
    margin-bottom: 1rem;
  }
}

@media print, screen and (min-width: 62em) {
  .large-p-margin {
    margin-bottom: 1rem;
  }
}

span.phrasing-block {
  display: block;
}

.preserve-lines {
  white-space: pre;
}

@media print, screen and (min-width: 48em) {
  .medium-preserve-lines {
    white-space: pre;
  }
}

@media print, screen and (min-width: 62em) {
  .large-preserve-lines {
    white-space: pre;
  }
}

.article-content small {
  text-align: center;
  font-style: italic;
  display: block;
}

.text-muted {
  color: #767676;
}

.text-dark .text-muted, .callout.primary .text-muted, .callout.secondary .text-muted, .callout.subtle .text-muted, .callout.success .text-muted, .callout.warning .text-muted, .callout.alert .text-muted {
  color: #ffffff89;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .text-muted {
    color: #ffffff89;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .text-muted {
    color: #ffffff89;
  }
}

.article-content ol, .article-content ul {
  margin-left: 1.75rem;
}

.article-layout-container {
  max-width: 50rem;
}

.article-layout-footer {
  margin-top: 2.08333rem;
}

@media print, screen and (min-width: 48em) {
  .article-layout-footer {
    margin-top: 3.33333rem;
  }
}

@media print, screen and (min-width: 62em) {
  .article-layout-footer {
    margin-top: 3.33333rem;
  }
}

body {
  min-width: 320px;
}

.catalog-card-layout {
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  display: grid;
}

@media print, screen and (min-width: 48em) {
  .catalog-card-layout {
    grid-gap: 1.875rem;
  }
}

@media print, screen and (min-width: 62em) {
  .catalog-card-layout {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
}

.clients-layout-heading {
  margin-bottom: 1rem;
}

@media print, screen and (max-width: 47.9988em) {
  .clients-layout-heading {
    margin-right: -6ch;
    transform: rotate(-90deg)translateY(-3ch);
  }
}

.clients-layout-logos {
  margin-top: -.5rem;
  margin-bottom: .5rem;
}

@media print, screen and (max-width: 47.9988em) {
  .clients-layout-logos {
    margin-left: -1.25rem;
    margin-right: -1rem;
  }
}

.clients-layout-logos > .packed-logos {
  margin-bottom: 0;
}

@media print, screen and (min-width: 48em) {
  .contact-form-main {
    flex-direction: column;
    display: flex;
  }

  .contact-form-main > label {
    flex-direction: column;
    flex: 1 0;
    display: flex;
  }

  .contact-form-main > label textarea {
    resize: vertical;
    flex: auto;
  }
}

.dual-action-layout {
  z-index: 0;
  background: linear-gradient(150deg, #da502d 0%, #d33535 40%, #cc2e3d 100%);
  position: relative;
}

@media print, screen and (min-width: 48em) {
  .dual-action-layout-grid {
    height: 56.25vw;
    max-height: 80vh;
    position: relative;
  }

  .dual-action-layout-compact .dual-action-layout-grid {
    height: auto;
  }
}

@media screen and (min-width: 75em) {
  .dual-action-layout-grid {
    height: 42.1875rem;
  }

  .dual-action-layout-compact .dual-action-layout-grid {
    height: auto;
  }
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-grid > .cell {
    padding-left: 1rem;
  }
}

.grid-padding-x > .dual-action-layout-left-edge-cell.cell {
  padding-left: 1rem;
}

@media print, screen and (min-width: 48em) {
  .grid-padding-x > .dual-action-layout-left-edge-cell.cell {
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 75em) {
  .grid-padding-x > .dual-action-layout-left-edge-cell.cell {
    padding-left: calc(50% - 36rem);
  }
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-grid > .cell {
    padding-right: 1rem;
  }
}

.grid-padding-x > .dual-action-layout-right-edge-cell.cell {
  padding-right: 1rem;
}

@media print, screen and (min-width: 48em) {
  .grid-padding-x > .dual-action-layout-right-edge-cell.cell {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 75em) {
  .grid-padding-x > .dual-action-layout-right-edge-cell.cell {
    padding-right: calc(50% - 36rem);
  }
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-heading-cell {
    margin-top: 2.08333rem;
  }
}

@media print, screen and (min-width: 48em) {
  .dual-action-layout-heading-cell {
    white-space: nowrap;
  }
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-left-cell {
    padding-top: 2rem;
    padding-bottom: 1rem;
    position: relative;
  }
}

@media print, screen and (min-width: 48em) {
  .dual-action-layout-left-cell {
    height: 100%;
    position: relative;
  }
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-right-cell {
    clip-path: polygon(0% 1.5rem, 100% -1.5rem, 100% 100%, 0% 100%);
    padding-top: 2rem;
    padding-bottom: 1rem;
    position: relative;
  }

  .dual-action-layout-right-cell:after {
    content: "";
    z-index: -10;
    background: linear-gradient(150deg, #4b6768ea 0%, #424d4ef1 50%, #394649df 100%);
    display: block;
    position: absolute;
    inset: -1.5rem 0 0;
  }
}

@media print, screen and (min-width: 48em) {
  .dual-action-layout-right-cell {
    clip-path: polygon(6rem 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 100%;
    position: relative;
  }

  .dual-action-layout-right-cell:after {
    content: "";
    z-index: -10;
    background: linear-gradient(150deg, #4b6768ea 0%, #424d4ef1 50%, #394649df 100%);
    display: block;
    position: absolute;
    inset: -.5px 0 0 -3rem;
  }
}

.dual-action-layout-right-cell-background {
  z-index: -10;
  position: absolute;
  inset: 0;
}

.dual-action-layout-right-cell-background.ratio-box {
  width: auto;
  height: auto;
  position: absolute;
  padding-bottom: 0 !important;
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-right-cell-background {
    top: -1.5rem;
  }
}

@media print, screen and (min-width: 48em) {
  .dual-action-layout-right-cell-background {
    top: -1px;
    left: -3rem;
  }

  .dual-action-layout-left-inner-grid, .dual-action-layout-right-inner-grid {
    height: 100%;
  }
}

@media print, screen and (max-width: 47.9988em) {
  .dual-action-layout-compensation {
    height: 3rem;
  }
}

.footer-layout-grid {
  grid-template-areas: "about"
                       "contact"
                       "explore"
                       "services"
                       "reference"
                       "careers";
  display: grid;
}

@media print, screen and (min-width: 48em) {
  .footer-layout-grid {
    grid-template-areas: "about contact services"
                         "about explore services"
                         "about reference services"
                         "about careers careers";
  }
}

.footer-layout-about {
  grid-area: about;
}

.footer-layout-careers {
  grid-area: careers;
}

.footer-layout-contact {
  grid-area: contact;
}

.footer-layout-explore {
  grid-area: explore;
}

.footer-layout-reference {
  grid-area: reference;
}

.footer-layout-services {
  grid-area: services;
}

.footer-layout-about {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.gallery-header-layout {
  background: radial-gradient(#000a, #0007);
  position: relative;
}

.gallery-header-layout-background {
  z-index: -10;
  position: absolute;
  inset: 0;
}

.gallery-header-layout-background.ratio-box {
  width: auto;
  height: auto;
  position: absolute;
  padding-bottom: 0 !important;
}

.gallery-header-layout-background img {
  filter: grayscale() brightness(.9) contrast(1.5);
}

.gallery-header-layout-grid {
  min-height: 20rem;
}

@media (max-height: 45rem) {
  .gallery-header-layout-grid {
    min-height: 33.3333vh;
  }
}

.gallery-header-layout-preamble {
  text-align: center;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .gallery-header-layout-preamble {
    width: 50%;
  }
}

.h1-layout {
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 2rem), 0% 100%);
  background: linear-gradient(150deg, #dd521b 0%, #d33535 35%, #cc243d 100%);
  padding-top: 1rem;
  padding-bottom: 1.33333rem;
}

.h1-layout.text-dark h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p):not(.plain):before {
  background-color: #00a4d1;
}

.h1-layout-compensation {
  height: 2rem;
}

.header-layout {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.header-layout:not(.header-layout-transparent) {
  background-color: #fff;
  box-shadow: 0 0 .5rem #0000001c;
}

.header-layout.header-layout-transparent {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.images-layout {
  max-width: calc(177.778vh - 5.33333rem);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media print, screen and (min-width: 48em) {
  .images-layout {
    flex-flow: row;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .images-layout {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media print and (min-width: 1280px), screen and (min-width: 1280px) and (min-width: 48em) {
  .images-layout {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.images-layout-main {
  height: 0;
  position: relative;
}

@media print, screen and (max-width: 47.9988em) {
  .images-layout-main {
    width: 100% !important;
    padding-bottom: 56.25% !important;
  }
}

.images-layout-main-content {
  position: absolute;
  inset: 0;
}

.images-layout-main-content:not(.active) {
  display: none;
}

.images-layout-main-content > .ratio-box {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-bottom: 0 !important;
}

@media print, screen and (max-width: 47.9988em) {
  .images-layout-nav {
    flex-flow: wrap;
    justify-content: center;
    padding: 1pt;
    display: flex;
  }
}

@media print, screen and (min-width: 48em) {
  .images-layout-nav {
    flex-grow: 1;
    grid-auto-flow: column;
    display: grid;
  }
}

@media print, screen and (max-width: 47.9988em) {
  .images-layout-nav-item {
    margin: 1pt;
  }

  .images-layout-nav-item > .ratio-box {
    width: 48px;
    height: 48px;
    padding-bottom: 0 !important;
  }
}

@media print, screen and (min-width: 48em) {
  .images-layout-nav-item {
    position: relative;
  }

  .images-layout-nav-item > .ratio-box {
    width: 100%;
    height: 100%;
    position: absolute;
    padding-bottom: 0 !important;
  }
}

.no-wrap {
  white-space: nowrap;
}

@media print, screen and (min-width: 48em) {
  .medium-left {
    float: left;
    width: 50%;
  }

  .article-content .medium-left {
    margin-right: 1rem;
  }

  .medium-right {
    float: right;
    width: 50%;
  }

  .article-content .medium-right {
    margin-left: 1rem;
  }
}

@media print, screen and (min-width: 62em) {
  .large-left {
    float: left;
    width: 50%;
  }

  .article-content .large-left {
    margin-right: 1rem;
  }

  .large-right {
    float: right;
    width: 50%;
  }

  .article-content .large-right {
    margin-left: 1rem;
  }
}

.text-dark {
  color: #fff;
}

.section-dark {
  background-color: #222;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark {
    color: #fff;
  }

  .medium-section-dark {
    background-color: #222;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark {
    color: #fff;
  }

  .large-section-dark {
    background-color: #222;
  }
}

.section-bowtie {
  clip-path: polygon(0% 0%, 50% .5rem, 100% 0%, 100% 100%, 50% calc(100% - .5rem), 0% 100%);
  z-index: 20;
  margin-top: calc(-.5rem - 1px);
  margin-bottom: calc(-.5rem - 1px);
  padding-top: 1.5rem;
  padding-bottom: .5rem;
  position: relative;
}

.section-bowtie.blue {
  background: linear-gradient(150deg, #00aabe 0%, #0b8eb1 50%, #0b7b9b 100%);
}

.section-bowtie.red {
  background: linear-gradient(150deg, #cc3d2f 0%, #cc3339 50%, #cc2f3d 100%);
}

.section-bowtie-compensation {
  height: .5rem;
}

.section-padded {
  padding-top: 2.08333rem;
  padding-bottom: 1.08333rem;
}

@media print, screen and (min-width: 48em) {
  .section-padded {
    padding-top: 3.33333rem;
    padding-bottom: 2.33333rem;
  }
}

@media print, screen and (min-width: 62em) {
  .section-padded {
    padding-top: 3.33333rem;
    padding-bottom: 2.33333rem;
  }
}

.section-padded-grid {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

@media print, screen and (min-width: 48em) {
  .section-padded-grid {
    padding-top: 1.875rem;
    padding-bottom: .875rem;
  }
}

.section-side-padded {
  padding-left: 2.08333rem;
  padding-right: 2.08333rem;
}

@media print, screen and (min-width: 48em) {
  .medium-section-side-padded {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem;
  }
}

@media print, screen and (min-width: 62em) {
  .large-section-side-padded {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem;
  }
}

.section-pnw {
  background-image: linear-gradient(#113c42db, #002d33e6), url("../../images/washington.d61a13f7.jpg");
  background-position: 50% 45%;
  background-repeat: no-repeat;
  background-size: cover;
}

.baz-layout {
  background: radial-gradient(#131e25cc, #14142088);
  position: relative;
}

.baz-layout h1 .phrasing-block:after {
  content: "";
  border-bottom: .5rem solid #00a4c2;
  width: 6rem;
  margin: 1rem auto .5rem;
  display: block;
}

.baz-layout-background {
  z-index: -10;
  position: absolute;
  inset: 0;
}

.baz-layout-background.ratio-box {
  width: auto;
  height: auto;
  position: absolute;
  padding-bottom: 0 !important;
}

.baz-layout-grid {
  min-height: 30rem;
}

@media (max-height: 45rem) {
  .baz-layout-grid {
    min-height: 66.6667vh;
  }
}

.tile-menu-layout {
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  display: grid;
}

@media print, screen and (min-width: 48em) {
  .tile-menu-layout {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }
}

@media print, screen and (min-width: 62em) {
  .tile-menu-layout {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
}

.tile-menu-layout.front-page {
  grid-template-columns: repeat(2, 1fr);
}

@media print, screen and (min-width: 48em) {
  .tile-menu-layout.front-page {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 112.5em) {
  .tile-menu-layout.front-page {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media print, screen and (min-width: 62em) {
  .tile-menu-layout.front-page .tile-menu-layout-cell {
    padding-bottom: 56.25%;
  }

  .tile-menu-layout.front-page .tile-menu-layout-cell-background {
    padding-bottom: 56.25% !important;
  }
}

@media screen and (min-width: 112.5em) {
  .tile-menu-layout.front-page .tile-menu-layout-cell {
    padding-bottom: 100%;
  }

  .tile-menu-layout.front-page .tile-menu-layout-cell-background {
    padding-bottom: 100% !important;
  }
}

.tile-menu-layout-cell {
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

@media print, screen and (min-width: 48em) {
  .tile-menu-layout-cell {
    font-size: 1.25rem;
  }
}

@media print, screen and (min-width: 62em) {
  .tile-menu-layout-cell {
    font-size: 1.4rem;
  }
}

a.tile-menu-layout-cell:active .tile-menu-layout-content, a.tile-menu-layout-cell:focus .tile-menu-layout-content, a.tile-menu-layout-cell:hover .tile-menu-layout-content {
  transition-duration: .2s;
  transform: translateX(-1em);
}

a.tile-menu-layout-cell:active .tile-menu-layout-content:after, a.tile-menu-layout-cell:focus .tile-menu-layout-content:after, a.tile-menu-layout-cell:hover .tile-menu-layout-content:after {
  opacity: 1;
  transition-duration: .2s;
  transform: translateX(0);
}

.tile-menu-layout-content {
  text-align: right;
  padding: 1rem;
  font-weight: 300;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: ease;
  position: relative;
  transform: translateX(0);
}

.tile-menu-layout-content:after {
  content: "›";
  opacity: 0;
  align-items: center;
  font-size: 2em;
  font-weight: 400;
  transition-property: opacity, transform;
  transition-duration: .2s;
  transition-timing-function: ease;
  display: flex;
  position: absolute;
  top: -.15em;
  bottom: 0;
  left: calc(100% - 1rem + .25em);
  transform: translateX(-.25em);
}

.tile-menu-layout-content strong {
  font-weight: 700;
}

@media print, screen and (min-width: 48em) {
  .tile-menu-layout-content strong {
    white-space: nowrap;
  }
}

.tile-menu-layout-cell-background {
  padding-bottom: 100% !important;
}

.tile-menu-layout-cell-foreground {
  background: linear-gradient(0deg, #131e25dd, #131e2599 calc(1rem + 3em), #131e2500 calc(1rem + 7em), #131e2500);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: absolute;
  inset: 0;
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(.5rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.accordion-title .label:first-of-type {
  margin-left: .5rem;
}

.article-card {
  margin-bottom: 1rem;
  display: block;
}

a.article-card .article-card-section {
  transition-property: border-top-color;
  transition-duration: .1s;
  transition-timing-function: ease;
}

a.article-card:hover .article-card-section {
  border-top-color: #cc2e3d;
}

.article-card-image {
  margin-bottom: 1rem;
  display: block;
}

.text-dark .article-card-image, .callout.primary .article-card-image, .callout.secondary .article-card-image, .callout.subtle .article-card-image, .callout.success .article-card-image, .callout.warning .article-card-image, .callout.alert .article-card-image {
  margin-bottom: 0;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .article-card-image {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .article-card-image {
    margin-bottom: 0;
  }
}

.article-card-image .ratio-box {
  padding-bottom: 56.25% !important;
}

.article-card-section {
  border-top: 2pt solid #65767d;
  padding-top: .5rem;
}

.text-dark .article-card-section, .callout.primary .article-card-section, .callout.secondary .article-card-section, .callout.subtle .article-card-section, .callout.success .article-card-section, .callout.warning .article-card-section, .callout.alert .article-card-section {
  background: #383838;
  border-top: none;
  padding: 1rem;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .article-card-section {
    background: #383838;
    border-top: none;
    padding: 1rem;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .article-card-section {
    background: #383838;
    border-top: none;
    padding: 1rem;
  }
}

.article-card-summary {
  margin-bottom: 0;
  font-family: Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 80%;
}

.text-dark .article-card-summary, .callout.primary .article-card-summary, .callout.secondary .article-card-summary, .callout.subtle .article-card-summary, .callout.success .article-card-summary, .callout.warning .article-card-summary, .callout.alert .article-card-summary {
  font-family: inherit;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .article-card-summary {
    font-family: inherit;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .article-card-summary {
    font-family: inherit;
  }
}

.article-card-title {
  text-rendering: optimizelegibility;
  margin-bottom: .5rem;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

a.article-card-title {
  transition-property: color;
  transition-duration: .1s;
  transition-timing-function: ease;
}

a.article-card-title:hover {
  color: #65767d;
}

.text-dark .button.clear, .text-dark .button.hollow {
  color: #fff;
}

.text-dark .button.clear:focus, .text-dark .button.clear:hover, .text-dark .button.hollow:focus, .text-dark .button.hollow:hover {
  color: #ffffffa8;
}

.callout.primary .button.clear, .callout.primary .button.hollow {
  color: #fff;
}

.callout.primary .button.clear:focus, .callout.primary .button.clear:hover, .callout.primary .button.hollow:focus, .callout.primary .button.hollow:hover {
  color: #ffffffa8;
}

.callout.secondary .button.clear, .callout.secondary .button.hollow {
  color: #fff;
}

.callout.secondary .button.clear:focus, .callout.secondary .button.clear:hover, .callout.secondary .button.hollow:focus, .callout.secondary .button.hollow:hover {
  color: #ffffffa8;
}

.callout.subtle .button.clear, .callout.subtle .button.hollow {
  color: #fff;
}

.callout.subtle .button.clear:focus, .callout.subtle .button.clear:hover, .callout.subtle .button.hollow:focus, .callout.subtle .button.hollow:hover {
  color: #ffffffa8;
}

.callout.success .button.clear, .callout.success .button.hollow {
  color: #fff;
}

.callout.success .button.clear:focus, .callout.success .button.clear:hover, .callout.success .button.hollow:focus, .callout.success .button.hollow:hover {
  color: #ffffffa8;
}

.callout.warning .button.clear, .callout.warning .button.hollow {
  color: #fff;
}

.callout.warning .button.clear:focus, .callout.warning .button.clear:hover, .callout.warning .button.hollow:focus, .callout.warning .button.hollow:hover {
  color: #ffffffa8;
}

.callout.alert .button.clear, .callout.alert .button.hollow {
  color: #fff;
}

.callout.alert .button.clear:focus, .callout.alert .button.clear:hover, .callout.alert .button.hollow:focus, .callout.alert .button.hollow:hover {
  color: #ffffffa8;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .button.clear, .medium-text-dark .button.hollow {
    color: #fff;
  }

  .medium-text-dark .button.clear:focus, .medium-text-dark .button.clear:hover, .medium-text-dark .button.hollow:focus, .medium-text-dark .button.hollow:hover {
    color: #ffffffa8;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .button.clear, .large-text-dark .button.hollow {
    color: #fff;
  }

  .large-text-dark .button.clear:focus, .large-text-dark .button.clear:hover, .large-text-dark .button.hollow:focus, .large-text-dark .button.hollow:hover {
    color: #ffffffa8;
  }
}

.button.hollow {
  border-color: #fff;
  transition-property: border-color, color;
}

.button.hollow:focus, .button.hollow:hover {
  border-color: #ffffffa8;
}

.button.large {
  font-weight: 700;
}

.bio {
  font-size: 80%;
  font-style: italic;
}

.article-head .bio {
  margin-top: 1rem;
}

.button .fa-chevron-right {
  vertical-align: -.15em;
}

.byline {
  font-size: 80%;
}

.article-head .byline {
  margin-top: 1rem;
}

.callout {
  text-align: left;
}

@media print, screen and (min-width: 48em) {
  .callout {
    padding: 2rem 2rem 1rem;
  }
}

@media print, screen and (min-width: 62em) {
  .callout {
    padding: 3rem 3rem 2rem;
  }

  .large-4 .callout {
    padding: 2rem 2rem 1rem;
  }
}

.callout.health-check {
  color: #fff;
  background-color: #d700d7;
  border: none;
  border-radius: 0;
  padding: 1rem;
}

.article-content .callout.health-check {
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: normal;
}

a.card {
  transition-property: box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: ease;
  position: relative;
}

a.card:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  border: 2px solid #cc2e3d;
  border-radius: 2pt;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: ease;
  display: block;
  position: absolute;
  inset: 0;
}

.catalog-card-layout a.card {
  transform: translateY(0);
}

.catalog-card-layout a.card:hover {
  transform: translateY(-.25rem);
}

a.card:hover {
  box-shadow: 0 0 1rem #00000026;
}

a.card:hover:before {
  opacity: 1;
}

.article-content .card {
  color: #3b3b3b;
}

.text-dark .card, .callout.primary .card, .callout.secondary .card, .callout.subtle .card, .callout.success .card, .callout.warning .card, .callout.alert .card {
  box-shadow: none;
  color: #fff;
  background: #383838;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .card {
    box-shadow: none;
    color: #fff;
    background: #383838;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .card {
    box-shadow: none;
    color: #fff;
    background: #383838;
  }
}

.article-body > .card {
  max-width: 40ch;
  margin-left: auto;
  margin-right: auto;
}

.card.top-seller {
  position: relative;
}

.card.top-seller:after {
  color: #fff;
  content: "Top\a Seller";
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  white-space: pre-line;
  background-color: #cc2e3d;
  justify-content: center;
  align-items: flex-end;
  width: 8rem;
  height: 4rem;
  padding-bottom: .2rem;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  position: absolute;
  top: -1.6rem;
  right: -3.6rem;
  overflow: hidden;
  transform: rotate(45deg);
}

.card-summary {
  font-size: 80%;
}

.card-title {
  text-rendering: optimizelegibility;
  margin-bottom: .5rem;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

ul.checklist, ul.checklist ul {
  list-style-type: none;
}

ul.checklist li, ul.checklist ul li {
  position: relative;
}

ul.checklist li:before, ul.checklist ul li:before {
  content: "☐";
  position: absolute;
  left: -1.25rem;
}

.embed-placeholder {
  margin: 2.08333rem;
}

.embed-placeholder, .embed-placeholder > a {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media print, screen and (min-width: 48em) {
  .embed-placeholder {
    margin: 3.33333rem;
  }
}

@media print, screen and (min-width: 62em) {
  .embed-placeholder {
    margin: 3.33333rem;
  }
}

.article-content .embed-placeholder {
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: normal;
}

.fa-ul {
  list-style-type: none;
}

span.fab {
  vertical-align: -.166667em;
  width: 1.25em;
  height: 1em;
  display: inline-block;
  position: relative;
}

span.fab:after {
  content: "•";
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: visible;
}

span.fal {
  vertical-align: -.166667em;
  width: 1.25em;
  height: 1em;
  display: inline-block;
  position: relative;
}

span.fal:after {
  content: "•";
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: visible;
}

span.far {
  vertical-align: -.166667em;
  width: 1.25em;
  height: 1em;
  display: inline-block;
  position: relative;
}

span.far:after {
  content: "•";
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: visible;
}

span.fas {
  vertical-align: -.166667em;
  width: 1.25em;
  height: 1em;
  display: inline-block;
  position: relative;
}

span.fas:after {
  content: "•";
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: visible;
}

body.error .hide-for-error {
  display: none;
}

hr.blue {
  border-bottom: .5rem solid #00a4c2;
  width: 4rem;
}

.ratio-box {
  width: 100%;
  height: 0;
  display: block;
  position: relative;
}

.ratio-box > img, .ratio-box > video {
  object-fit: cover;
  object-position: 50% 33%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-box.letterbox > img, .ratio-box.letterbox > video {
  object-fit: contain;
}

.ratio-box.fill-height {
  height: 100%;
  padding-bottom: 0 !important;
}

@media print, screen and (min-width: 48em) {
  .ratio-box.medium-fill-height {
    height: 100%;
    padding-bottom: 0 !important;
  }
}

@media print, screen and (min-width: 62em) {
  .ratio-box.large-fill-height {
    height: 100%;
    padding-bottom: 0 !important;
  }
}

@media print, screen and (min-width: 48em) {
  .ratio-box.medium-50-56 > img {
    object-position: 50% 56% !important;
  }
}

.ratio-box-toolbar {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.ratio-box:hover .ratio-box-toolbar {
  opacity: 1;
}

.facebook-embed {
  text-align: center;
  margin-bottom: 1rem;
}

.article-content figcaption {
  text-align: center;
  font-size: .8rem;
}

figure {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

figure.caption figcaption {
  background-color: #222;
  padding: 1rem 1rem 0;
  font-size: 80%;
  overflow: hidden;
}

@media print, screen and (min-width: 62em) {
  figure.caption.large-caption-overlay {
    position: relative;
  }

  figure.caption.large-caption-overlay figcaption {
    background-color: #222222bf;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  figure.large-cover {
    width: 100%;
    height: 100%;
  }

  figure.large-cover > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  figure.large-cover > .ratio-box {
    width: 100%;
    height: 100%;
    padding-bottom: 0 !important;
  }

  figure.large-cover > .ratio-box > img {
    object-fit: cover;
  }
}

@media print, screen and (max-width: 47.9988em) {
  .small-only-fold.collapsed:not(:focus) {
    cursor: zoom-in;
    max-height: 9.06667em;
    position: relative;
    overflow: hidden;
  }

  .small-only-fold.collapsed:not(:focus):after {
    color: #a3a3a3;
    content: "⯆";
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
    background: linear-gradient(0deg, #fefefe, #fefefe .8em, #fefefe00);
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    min-height: 3.2em;
    transition-property: color;
    transition-duration: .1s;
    transition-timing-function: ease-out;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .text-dark .small-only-fold.collapsed:not(:focus):after, .callout.primary .small-only-fold.collapsed:not(:focus):after, .callout.secondary .small-only-fold.collapsed:not(:focus):after, .callout.subtle .small-only-fold.collapsed:not(:focus):after, .callout.success .small-only-fold.collapsed:not(:focus):after, .callout.warning .small-only-fold.collapsed:not(:focus):after, .callout.alert .small-only-fold.collapsed:not(:focus):after {
    color: #5d5d5dc4;
    background: linear-gradient(0deg, #222, #222 .8em, #2220);
  }
}

@media print, screen and (max-width: 47.9988em) and (min-width: 48em) {
  .medium-text-dark .small-only-fold.collapsed:not(:focus):after {
    color: #5d5d5dc4;
    background: linear-gradient(0deg, #222, #222 .8em, #2220);
  }
}

@media print, screen and (max-width: 47.9988em) and (min-width: 62em) {
  .large-text-dark .small-only-fold.collapsed:not(:focus):after {
    color: #5d5d5dc4;
    background: linear-gradient(0deg, #222, #222 .8em, #2220);
  }
}

@media print, screen and (max-width: 47.9988em) {
  .small-only-fold.collapsed:not(:focus):hover:after {
    color: #007491;
    transition-duration: 50ms;
  }

  .text-dark .small-only-fold.collapsed:not(:focus):hover:after, .callout.primary .small-only-fold.collapsed:not(:focus):hover:after, .callout.secondary .small-only-fold.collapsed:not(:focus):hover:after, .callout.subtle .small-only-fold.collapsed:not(:focus):hover:after, .callout.success .small-only-fold.collapsed:not(:focus):hover:after, .callout.warning .small-only-fold.collapsed:not(:focus):hover:after, .callout.alert .small-only-fold.collapsed:not(:focus):hover:after {
    color: #00aeda;
  }
}

@media print, screen and (max-width: 47.9988em) and (min-width: 48em) {
  .medium-text-dark .small-only-fold.collapsed:not(:focus):hover:after {
    color: #00aeda;
  }
}

@media print, screen and (max-width: 47.9988em) and (min-width: 62em) {
  .large-text-dark .small-only-fold.collapsed:not(:focus):hover:after {
    color: #00aeda;
  }
}

@media print, screen and (min-width: 62em) {
  .large-cover-container {
    min-height: 100%;
    position: relative;
  }

  .large-cover-container-container {
    position: relative;
  }
}

img.lazyload:not([src]) {
  opacity: 0;
}

.footer-icons {
  font-size: 1.5rem;
}

.footer-icons a:not(:focus):not(:hover) {
  color: #65767d;
}

.footer-icons li:not(:last-child) {
  margin-right: .666667rem;
}

.footer-nav a:not(:focus):not(:hover) {
  text-decoration-color: #0000;
}

.footer-nav abbr {
  border-bottom: none;
}

.header-nav .button.caps {
  font-size: .9rem;
}

@media screen and (max-width: 29.0625em) {
  .header-nav .button.caps {
    font-size: .75rem;
  }
}

.header-nav .button.clear {
  color: inherit;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 23.4375em) {
  .header-nav-contact.cell {
    flex: none;
    width: auto;
  }
}

.header-nav-minimal .header-nav-contact.cell {
  flex: none;
  width: auto;
}

body.page-id-19 .header-nav-contact.cell {
  display: none;
}

@media screen and (max-width: 23.375em) {
  .header-nav-our-work-br {
    display: none;
  }
}

@media screen and (min-width: 25.875em) {
  .header-nav-our-work-br {
    display: none;
  }
}

.article-content hr {
  border: none;
  margin-bottom: 2.08333rem;
}

@media print, screen and (min-width: 48em) {
  .article-content hr {
    margin-bottom: 3.33333rem;
  }
}

@media print, screen and (min-width: 62em) {
  .article-content hr {
    margin-bottom: 3.33333rem;
  }
}

hr.geometric {
  clip-path: polygon(0% calc(100% - 1rem), calc(50% - 1.5rem) 0%, calc(50% + 1.5rem) calc(100% - 1rem), 100% 0%, 100% 1rem, calc(50% + 1.5rem) 100%, calc(50% - 1.5rem) 1rem, 0% 100%);
  max-width: initial;
  z-index: 10;
  background: linear-gradient(150deg, #00aabe 0%, #0b8eb1 50%, #0b7b9b 100%);
  border: none;
  height: 3rem;
  margin: 0;
  display: block;
  position: relative;
}

.icon-grid {
  grid-gap: 1rem;
  text-align: left;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  margin: 0 auto 1rem;
  display: grid;
}

.icon-grid.small-1 {
  grid-template-columns: repeat(1, 1fr);
}

@media print, screen and (min-width: 48em) {
  .icon-grid.medium-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media print, screen and (min-width: 62em) {
  .icon-grid.large-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.icon-grid.small-2 {
  grid-template-columns: repeat(2, 1fr);
}

@media print, screen and (min-width: 48em) {
  .icon-grid.medium-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media print, screen and (min-width: 62em) {
  .icon-grid.large-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.icon-grid.small-3 {
  grid-template-columns: repeat(3, 1fr);
}

@media print, screen and (min-width: 48em) {
  .icon-grid.medium-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media print, screen and (min-width: 62em) {
  .icon-grid.large-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.icon-grid-cell {
  align-items: center;
  display: flex;
}

.icon-grid-icon {
  color: #65767d;
  margin-right: 1rem;
  font-size: 1.5em;
}

.text-dark .icon-grid-icon, .callout.primary .icon-grid-icon, .callout.secondary .icon-grid-icon, .callout.subtle .icon-grid-icon, .callout.success .icon-grid-icon, .callout.warning .icon-grid-icon, .callout.alert .icon-grid-icon {
  color: inherit;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .icon-grid-icon {
    color: inherit;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .icon-grid-icon {
    color: inherit;
  }
}

.icon-grid-inline {
  display: inline-grid;
}

.icon-grid-label p {
  margin-bottom: 0;
}

.icon-grid-padded-small {
  grid-gap: 2.08333rem;
}

@media print, screen and (min-width: 48em) {
  .icon-grid-padded-medium {
    grid-gap: 3.33333rem;
  }
}

@media print, screen and (min-width: 62em) {
  .icon-grid-padded-large {
    grid-gap: 3.33333rem;
  }
}

@media print, screen and (min-width: 48em) {
  .icon-grid-padded-medium .icon-grid-icon, .icon-grid-padded-large .icon-grid-icon {
    font-size: 2.5em;
  }
}

.incremental-contact-form {
  margin-bottom: 1rem;
}

.incremental-contact-form-placeholder {
  height: 16em;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: middle;
}

input.hollow, textarea.hollow {
  color: #0a0a0a;
  padding-left: 0;
  padding-right: 0;
}

input.hollow, input.hollow:focus, textarea.hollow, textarea.hollow:focus {
  box-shadow: none;
  background-color: #0000;
  border: none;
  border-bottom: 2pt solid #0a0a0a13;
  transition-property: border-bottom-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;
}

input.hollow:focus, textarea.hollow:focus {
  border-bottom-color: #cc2e3d;
  transition-duration: .1s;
}

input.hollow::placeholder, textarea.hollow::placeholder {
  color: #76767680;
}

.text-dark input.hollow, .text-dark textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.text-dark input.hollow:focus, .text-dark textarea.hollow:focus {
  border-bottom-color: #fff;
}

.text-dark input.hollow::placeholder, .text-dark textarea.hollow::placeholder {
  color: #ffffff80;
}

.callout.primary input.hollow, .callout.primary textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.callout.primary input.hollow:focus, .callout.primary textarea.hollow:focus {
  border-bottom-color: #fff;
}

.callout.primary input.hollow::placeholder, .callout.primary textarea.hollow::placeholder {
  color: #ffffff80;
}

.callout.secondary input.hollow, .callout.secondary textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.callout.secondary input.hollow:focus, .callout.secondary textarea.hollow:focus {
  border-bottom-color: #fff;
}

.callout.secondary input.hollow::placeholder, .callout.secondary textarea.hollow::placeholder {
  color: #ffffff80;
}

.callout.subtle input.hollow, .callout.subtle textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.callout.subtle input.hollow:focus, .callout.subtle textarea.hollow:focus {
  border-bottom-color: #fff;
}

.callout.subtle input.hollow::placeholder, .callout.subtle textarea.hollow::placeholder {
  color: #ffffff80;
}

.callout.success input.hollow, .callout.success textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.callout.success input.hollow:focus, .callout.success textarea.hollow:focus {
  border-bottom-color: #fff;
}

.callout.success input.hollow::placeholder, .callout.success textarea.hollow::placeholder {
  color: #ffffff80;
}

.callout.warning input.hollow, .callout.warning textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.callout.warning input.hollow:focus, .callout.warning textarea.hollow:focus {
  border-bottom-color: #fff;
}

.callout.warning input.hollow::placeholder, .callout.warning textarea.hollow::placeholder {
  color: #ffffff80;
}

.callout.alert input.hollow, .callout.alert textarea.hollow {
  color: #fff;
  border-bottom-color: #ffffff26;
}

.callout.alert input.hollow:focus, .callout.alert textarea.hollow:focus {
  border-bottom-color: #fff;
}

.callout.alert input.hollow::placeholder, .callout.alert textarea.hollow::placeholder {
  color: #ffffff80;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark input.hollow, .medium-text-dark textarea.hollow {
    color: #fff;
    border-bottom-color: #ffffff26;
  }

  .medium-text-dark input.hollow:focus, .medium-text-dark textarea.hollow:focus {
    border-bottom-color: #fff;
  }

  .medium-text-dark input.hollow::placeholder, .medium-text-dark textarea.hollow::placeholder {
    color: #ffffff80;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark input.hollow, .large-text-dark textarea.hollow {
    color: #fff;
    border-bottom-color: #ffffff26;
  }

  .large-text-dark input.hollow:focus, .large-text-dark textarea.hollow:focus {
    border-bottom-color: #fff;
  }

  .large-text-dark input.hollow::placeholder, .large-text-dark textarea.hollow::placeholder {
    color: #ffffff80;
  }
}

input.hollow.no-margin-bottom, textarea.hollow.no-margin-bottom {
  margin-bottom: -2pt;
}

.input-group-button .button.hollow {
  border: none;
  border-bottom: 2pt solid #0a0a0a13;
  outline: none;
}

.input-group-button .button.hollow:focus {
  border-bottom-color: #cc2e3d;
}

.text-dark .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.text-dark .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

.callout.primary .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.callout.primary .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

.callout.secondary .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.callout.secondary .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

.callout.subtle .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.callout.subtle .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

.callout.success .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.callout.success .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

.callout.warning .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.callout.warning .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

.callout.alert .input-group-button .button.hollow {
  border-bottom-color: #ffffff26;
}

.callout.alert .input-group-button .button.hollow:focus {
  border-bottom-color: #fff;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .input-group-button .button.hollow {
    border-bottom-color: #ffffff26;
  }

  .medium-text-dark .input-group-button .button.hollow:focus {
    border-bottom-color: #fff;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .input-group-button .button.hollow {
    border-bottom-color: #ffffff26;
  }

  .large-text-dark .input-group-button .button.hollow:focus {
    border-bottom-color: #fff;
  }
}

.label.subtle {
  color: #767676;
}

.label.subtle .svg-inline--fa {
  opacity: .8;
}

.text-dark .label.subtle, .callout.primary .label.subtle, .callout.secondary .label.subtle, .callout.subtle .label.subtle, .callout.success .label.subtle, .callout.warning .label.subtle, .callout.alert .label.subtle {
  color: #ffffff89;
  background-color: #ffffff2b;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .label.subtle {
    color: #ffffff89;
    background-color: #ffffff2b;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .label.subtle {
    color: #ffffff89;
    background-color: #ffffff2b;
  }
}

.label-plain {
  display: inline;
}

button.label {
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;
}

button.label:hover {
  transition-duration: 50ms;
}

button.label:hover.subtle {
  color: #6a6a6a;
  background-color: #cecece;
}

.text-dark button.label:hover.subtle, .callout.primary button.label:hover.subtle, .callout.secondary button.label:hover.subtle, .callout.subtle button.label:hover.subtle, .callout.success button.label:hover.subtle, .callout.warning button.label:hover.subtle, .callout.alert button.label:hover.subtle {
  color: #ffffff89;
  background-color: #fff5;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark button.label:hover.subtle {
    color: #ffffff89;
    background-color: #fff5;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark button.label:hover.subtle {
    color: #ffffff89;
    background-color: #fff5;
  }
}

.multidate-preview {
  margin-top: -.5rem;
  margin-bottom: 1rem;
  margin-left: -.666667ch;
}

.multidate-preview .multidate-result {
  margin-left: .666667ch;
  font-size: .8rem;
  display: inline-block;
}

.photostrip {
  margin-bottom: -1px;
  display: flex;
}

.article-content .photostrip {
  margin-bottom: 1rem;
}

.article-content .photostrip + .photostrip {
  margin-top: calc(-1px - 1rem);
}

img.photostrip-image {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.photostrip-image-container {
  background-size: 100% 100%;
  position: relative;
}

.post-edit-link {
  color: #65767d;
  transition-property: color;
  transition-duration: .1s;
  transition-timing-function: ease-out;
}

.post-edit-link:focus, .post-edit-link:hover {
  transition-duration: 50ms;
}

.text-dark .post-edit-link:not(:focus):not(:hover), .callout.primary .post-edit-link:not(:focus):not(:hover), .callout.secondary .post-edit-link:not(:focus):not(:hover), .callout.subtle .post-edit-link:not(:focus):not(:hover), .callout.success .post-edit-link:not(:focus):not(:hover), .callout.warning .post-edit-link:not(:focus):not(:hover), .callout.alert .post-edit-link:not(:focus):not(:hover) {
  color: #ffffff40;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .post-edit-link:not(:focus):not(:hover) {
    color: #ffffff40;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .post-edit-link:not(:focus):not(:hover) {
    color: #ffffff40;
  }
}

.radio-menu-item input {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: absolute;
}

.radio-menu-item .radio-menu-item-label {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-left: 3pt solid #0000;
  margin-bottom: .15em;
  padding-left: .5em;
  font-size: .8rem;
  transition-property: border-left-color;
  transition-duration: .1s;
  transition-timing-function: ease;
  display: block;
}

.radio-menu-item .radio-menu-item-label .badge {
  background-color: #65767d;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  min-width: 1rem;
  height: 1rem;
  margin-left: 1ch;
  padding: 0 .333333em .111111em;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1;
  display: inline-flex;
}

.radio-menu-item input:disabled + .radio-menu-item-label {
  color: #767676;
}

.radio-menu-item input:not(:disabled):not(:checked) + .radio-menu-item-label {
  cursor: pointer;
}

.radio-menu-item:active input:not(:disabled) + .radio-menu-item-label, .radio-menu-item input:checked + .radio-menu-item-label {
  border-left-color: #65767d;
  font-weight: 700;
}

.radio-menu-item:not(:active):hover input:not(:disabled):not(:checked) + .radio-menu-item-label {
  border-left-color: #65767d40;
}

.result {
  text-align: center;
  border: 2pt solid #0a0a0a1a;
  border-width: 2pt 0;
  margin-bottom: 1rem;
  padding-top: 1rem;
  animation: .5s ease-out slide-in-left;
}

.result.alert {
  border-color: #cc2e3d;
}

.text-dark .result {
  border-color: #ffffff4d;
}

.text-dark .result.alert {
  border-color: #fff;
}

.callout.primary .result {
  border-color: #ffffff4d;
}

.callout.primary .result.alert {
  border-color: #fff;
}

.callout.secondary .result {
  border-color: #ffffff4d;
}

.callout.secondary .result.alert {
  border-color: #fff;
}

.callout.subtle .result {
  border-color: #ffffff4d;
}

.callout.subtle .result.alert {
  border-color: #fff;
}

.callout.success .result {
  border-color: #ffffff4d;
}

.callout.success .result.alert {
  border-color: #fff;
}

.callout.warning .result {
  border-color: #ffffff4d;
}

.callout.warning .result.alert {
  border-color: #fff;
}

.callout.alert .result {
  border-color: #ffffff4d;
}

.callout.alert .result.alert {
  border-color: #fff;
}

@media print, screen and (min-width: 48em) {
  .medium-text-dark .result {
    border-color: #ffffff4d;
  }

  .medium-text-dark .result.alert {
    border-color: #fff;
  }
}

@media print, screen and (min-width: 62em) {
  .large-text-dark .result {
    border-color: #ffffff4d;
  }

  .large-text-dark .result.alert {
    border-color: #fff;
  }
}

.revealable-field-links {
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.revealed-field {
  animation: .5s ease-out slide-in-left;
}

.review-author {
  font-weight: bold;
}

.review-rating abbr {
  cursor: inherit;
  border-bottom: none;
}

.review-star {
  color: #e7711b;
}

@media print, screen and (max-width: 47.9988em) {
  .responsive-embed-spotify {
    padding-bottom: 280px;
  }
}

.series-intro {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 80%;
  font-style: italic;
}

body:not(.error) .show-for-error {
  display: none;
}

ul.packed-logos {
  margin-left: 0;
  list-style-type: none;
}

ul.packed-logos > li {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  margin: 0 .333333rem .166667rem;
  display: inline-block;
}

@media print, screen and (min-width: 48em) {
  ul.packed-logos > li {
    margin: 0 .5rem .25rem;
  }
}

ul.packed-logos > li img {
  width: auto;
  max-height: 2rem;
}

@media print, screen and (min-width: 48em) {
  ul.packed-logos > li img {
    max-height: 2.5rem;
  }
}

@media screen and (min-width: 75em) {
  ul.packed-logos > li img {
    max-height: 3rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ul.packed-logos > li img {
    width: 100%;
    height: 2rem;
  }
}

@media screen and (-ms-high-contrast: active) and (min-width: 48em), print and (-ms-high-contrast: none), screen and (-ms-high-contrast: none) and (min-width: 48em) {
  ul.packed-logos > li img {
    height: 2.5rem;
  }
}

@media screen and (-ms-high-contrast: active) and (min-width: 75em), screen and (-ms-high-contrast: none) and (min-width: 75em) {
  ul.packed-logos > li img {
    height: 3rem;
  }
}

.spacer {
  visibility: hidden;
  height: 1.25em;
}

.unresponsive-embed {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.unresponsive-embed > :last-child {
  margin-bottom: 0;
}

.wp-video {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

_:-ms-fullscreen .catalog-card-layout, :root .catalog-card-layout {
  flex-flow: wrap;
  display: flex;
}

_:-ms-fullscreen .catalog-card-layout > .cell, :root .catalog-card-layout > .cell {
  width: calc(25% - 1rem);
  margin-bottom: 1em;
}

_:-ms-fullscreen .catalog-card-layout > .cell:not(:last-child), :root .catalog-card-layout > .cell:not(:last-child) {
  margin-right: 1em;
}

@media print, screen and (min-width: 48em) {
  _:-ms-fullscreen .images-layout-nav, :root .images-layout-nav {
    flex-flow: column wrap;
    display: flex;
  }

  _:-ms-fullscreen .images-layout-nav-item, :root .images-layout-nav-item {
    flex-grow: 1;
  }
}

_:-ms-fullscreen .tile-menu-layout, _:-ms-fullscreen .tile-menu-layout.front-page, :root .tile-menu-layout, :root .tile-menu-layout.front-page {
  flex-flow: wrap;
  display: flex;
}

_:-ms-fullscreen .tile-menu-layout .tile-menu-layout-cell, _:-ms-fullscreen .tile-menu-layout.front-page .tile-menu-layout-cell, :root .tile-menu-layout .tile-menu-layout-cell, :root .tile-menu-layout.front-page .tile-menu-layout-cell {
  width: 25%;
  height: auto;
  padding-bottom: 0;
  position: relative;
}

_:-ms-fullscreen .tile-menu-layout .tile-menu-layout-content, _:-ms-fullscreen .tile-menu-layout.front-page .tile-menu-layout-content, :root .tile-menu-layout .tile-menu-layout-content, :root .tile-menu-layout.front-page .tile-menu-layout-content {
  max-width: 100%;
}

body.logged-in .article-content h1:empty:after, body.logged-in .article-content h2:empty:after, body.logged-in .article-content h3:empty:after, body.logged-in .article-content h4:empty:after, body.logged-in .article-content h5:empty:after, body.logged-in .article-content h6:empty:after, body.logged-in .item-content h1:empty:after, body.logged-in .item-content h2:empty:after, body.logged-in .item-content h3:empty:after, body.logged-in .item-content h4:empty:after, body.logged-in .item-content h5:empty:after, body.logged-in .item-content h6:empty:after {
  color: #f0f;
  content: "ℍ";
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  display: block;
}

body.logged-in .article-content h1 b, body.logged-in .article-content h1 strong, body.logged-in .article-content h2 b, body.logged-in .article-content h2 strong, body.logged-in .article-content h3 b, body.logged-in .article-content h3 strong, body.logged-in .article-content h4 b, body.logged-in .article-content h4 strong, body.logged-in .article-content h5 b, body.logged-in .article-content h5 strong, body.logged-in .article-content h6 b, body.logged-in .article-content h6 strong, body.logged-in .item-content h1 b, body.logged-in .item-content h1 strong, body.logged-in .item-content h2 b, body.logged-in .item-content h2 strong, body.logged-in .item-content h3 b, body.logged-in .item-content h3 strong, body.logged-in .item-content h4 b, body.logged-in .item-content h4 strong, body.logged-in .item-content h5 b, body.logged-in .item-content h5 strong, body.logged-in .item-content h6 b, body.logged-in .item-content h6 strong {
  -webkit-text-stroke: 1pt #f0f;
}

body.logged-in .article-content a[target="_blank"], body.logged-in .item-content a[target="_blank"] {
  border-bottom: 2pt solid #f0f !important;
}

body.logged-in .article-content img[class^="wp-image-"]:not([class^="size-"]):not([class*=" size-"]), body.logged-in .article-content img[class*=" wp-image-"]:not([class^="size-"]):not([class*=" size-"]), body.logged-in .item-content img[class^="wp-image-"]:not([class^="size-"]):not([class*=" size-"]), body.logged-in .item-content img[class*=" wp-image-"]:not([class^="size-"]):not([class*=" size-"]) {
  outline: 4pt solid #f0f;
}

body.logged-in .article-content p:empty:after, body.logged-in .item-content p:empty:after {
  color: #f0f;
  content: "¶";
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  display: block;
}

body.logged-in span.visible-br:before {
  color: #fff;
  content: "↵";
  background-color: #f0f;
  font-weight: bold;
}

body.logged-in span.visible-space {
  background-color: #f0f;
  border-right: 2pt solid #f0f;
}

/*# sourceMappingURL=main.css.map */
