//
// Internet Explorer
//

@mixin ie11 {
  _:-ms-fullscreen,
  :root {
    @content;
  }
}

@include ie11 {
  .catalog-card-layout {
    display: flex;
    flex-flow: row wrap;

    & > .cell {
      margin-bottom: 1em;
      width: calc(25% - 1rem);

      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }

  .images-layout-nav {
    @include breakpoint(medium) {
      display: flex;
      flex-flow: column wrap;
    }
  }

  .images-layout-nav-item {
    @include breakpoint(medium) {
      flex-grow: 1;
    }
  }

  .tile-menu-layout {
    &,
    &.front-page {
      display: flex;
      flex-flow: row wrap;

      .tile-menu-layout-cell {
        height: auto;
        padding-bottom: 0;
        position: relative;
        width: 25%;
      }

      .tile-menu-layout-content {
        max-width: 100%;
      }
    }
  }
}
