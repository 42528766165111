@use "sass:math";

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(0.5rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.accordion-title {
  .label:first-of-type {
    margin-left: 0.5rem;
  }
}

.article-card {
  display: block;
  margin-bottom: $paragraph-margin-bottom;
}

a.article-card {
  .article-card-section {
    transition-duration: 100ms;
    transition-property: border-top-color;
    transition-timing-function: ease;
  }

  &:hover {
    .article-card-section {
      border-top-color: get-color(primary);
    }
  }
}

.article-card-image {
  display: block;
  margin-bottom: $global-margin;

  @include if-dark {
    margin-bottom: 0;
  }

  .ratio-box {
    padding-bottom: calc(100% / (16 / 9)) !important;
  }
}

.article-card-section {
  border-top: 2pt solid get-color(secondary);
  padding-top: calc(#{$global-margin} * (1 / 2));

  @include if-dark {
    background: scale-color($dark-body-background, $lightness: 10%);
    border-top: none;
    padding: $global-margin;
  }
}

.article-card-summary {
  font-family: $article-font-family;
  font-size: $small-font-size;
  margin-bottom: 0;

  @include if-dark {
    font-family: inherit;
  }
}

.article-card-title {
  display: block;
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  line-height: $header-lineheight;
  margin-bottom: $header-margin-bottom;
  text-rendering: $header-text-rendering;
}

a.article-card-title {
  transition-duration: 100ms;
  transition-property: color;
  transition-timing-function: ease;

  &:hover {
    color: get-color(secondary);
  }
}

.button {
  @include if-dark {
    &.clear,
    &.hollow {
      color: $dark-body-font-color;

      &:focus,
      &:hover {
        color: rgba($dark-body-font-color, 0.66);
      }
    }
  }

  &.hollow {
    border-color: $dark-body-font-color;
    transition-property: border-color, color;

    &:focus,
    &:hover {
      border-color: rgba($dark-body-font-color, 0.66);
    }
  }
}

.button.large {
  font-weight: 700;
}

.bio {
  font-size: $small-font-size;
  font-style: italic;

  .article-head & {
    margin-top: 1rem;
  }
}

.button {
  .fa-chevron-right {
    vertical-align: -0.15em;
  }
}

.byline {
  font-size: $small-font-size;

  .article-head & {
    margin-top: 1rem;
  }
}

.callout {
  text-align: left;

  @include breakpoint(medium) {
    padding: 2rem 2rem calc(2rem - #{$paragraph-margin-bottom}) 2rem;
  }

  @include breakpoint(large) {
    padding: 3rem 3rem calc(3rem - #{$paragraph-margin-bottom}) 3rem;

    .large-4 & {
      padding: 2rem 2rem calc(2rem - #{$paragraph-margin-bottom}) 2rem;
    }
  }
}

.callout.health-check {
  background-color: #d700d7;
  border: none;
  border-radius: 0;
  color: #fff;
  padding: 1rem;

  // Undo
  @include if-article {
    font-family: $body-font-family;
    font-size: 1rem;
    font-weight: $global-weight-normal;
  }
}

a.card {
  position: relative;
  transition-duration: 200ms;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;

  &::before {
    border: 2px solid get-color(primary);
    border-radius: $card-border-radius;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease;
    z-index: 10;
  }

  .catalog-card-layout & {
    transform: translateY(0);

    &:hover {
      transform: translateY(-0.25rem);
    }
  }

  &:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);

    &::before {
      opacity: 1;
    }
  }
}

.card {
  @include if-article {
    color: scale-color($body-font-color, $lightness: 20%);
  }

  @include if-dark {
    background: scale-color($dark-body-background, $lightness: 10%);
    box-shadow: none;
    color: $dark-body-font-color;
  }

  .article-body > & {
    margin-left: auto;
    margin-right: auto;
    max-width: 40ch;
  }

  &.top-seller {
    position: relative;

    &::after {
      align-items: flex-end;
      background-color: get-color(primary);
      color: $dark-body-font-color;
      content: "Top\ASeller";
      display: flex;
      font-size: 0.75rem;
      font-weight: 700;
      height: 4rem;
      line-height: 1;
      justify-content: center;
      overflow: hidden;
      padding-bottom: 0.2rem;
      pointer-events: none;
      position: absolute;
      right: -3.6rem;
      text-align: center;
      text-transform: uppercase;
      top: -1.6rem;
      transform: rotate(45deg);
      user-select: none;
      white-space: pre-line;
      width: 8rem;
    }
  }
}

.card-summary {
  font-size: $small-font-size;
}

.card-title {
  display: block;
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  line-height: $header-lineheight;
  margin-bottom: $header-margin-bottom;
  text-rendering: $header-text-rendering;
}

ul.checklist {
  &,
  & ul {
    list-style-type: none;

    li {
      position: relative;

      &:before {
        content: "\2610";
        left: -1.25rem;
        position: absolute;
      }
    }
  }
}

.embed-placeholder {
  &,
  & > a {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      margin: section-padding($size);
    }
  }

  // Undo
  @include if-article {
    font-family: $body-font-family;
    font-size: 1rem;
    font-weight: $global-weight-normal;
  }
}

// FontAwesome placeholders
.fa-ul {
  list-style-type: none;
}
@each $namespace in (fab, fal, far, fas) {
  span.#{$namespace} {
    display: inline-block;
    height: 1em;
    position: relative;
    vertical-align: calc(-1 * 1em * 1 / 6);
    width: $fa-fw-width;

    &::after {
      align-items: center;
      bottom: 0;
      content: "\2022";
      display: flex;
      justify-content: center;
      left: 0;
      opacity: 0.5;
      overflow: visible;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.hide-for-error {
  body.error & {
    display: none;
  }
}

hr.blue {
  border-bottom: 0.5rem solid #00a4c2;
  width: 4rem;
}

.ratio-box {
  display: block;
  height: 0;
  position: relative;
  width: 100%;

  & > img,
  & > video {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: 50% 33%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.letterbox {
    & > img,
    & > video {
      object-fit: contain;
    }
  }

  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      @if $size == $-zf-zero-breakpoint {
        &.fill-height {
          height: 100%;
          padding-bottom: 0 !important;
        }
      } @else {
        &.#{$size}-fill-height {
          height: 100%;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  &.medium-50-56 > img {
    @include breakpoint(medium) {
      object-position: 50% 56% !important;
    }
  }
}

.ratio-box-toolbar {
  bottom: $global-margin;
  opacity: 0;
  position: absolute;
  right: $global-margin;
  transition-duration: 200ms;
  transition-property: opacity;
  transition-timing-function: ease-out;

  .ratio-box:hover & {
    opacity: 1;
  }
}

.facebook-embed {
  margin-bottom: $paragraph-margin-bottom;
  text-align: center;
}

figcaption {
  @include if-article {
    font-size: calc(1rem * #{strip-unit($small-font-size)} / 100);
    text-align: center;
  }
}

figure {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  &.caption {
    figcaption {
      background-color: $dark-body-background;
      font-size: $small-font-size;
      overflow: hidden;
      padding: $global-padding $global-padding 0 $global-padding;
    }

    &.large-caption-overlay {
      @include breakpoint(large) {
        position: relative;

        figcaption {
          background-color: rgba($dark-body-background, 0.75);
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  &.large-cover {
    @include breakpoint(large) {
      height: 100%;
      width: 100%;

      & > img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      & > .ratio-box {
        height: 100%;
        padding-bottom: 0 !important;
        width: 100%;

        & > img {
          object-fit: cover;
        }
      }
    }
  }
}

.small-only-fold {
  @include breakpoint(small down) {
    &.collapsed:not(:focus) {
      cursor: zoom-in;
      max-height: (5em + (1em * math.div(2, 3))) * $paragraph-lineheight;
      overflow: hidden;
      position: relative;

      &::after {
        align-items: center;
        background: linear-gradient(
          0deg,
          $body-background,
          $body-background #{0.5em * $paragraph-lineheight},
          change-color($body-background, $alpha: 0)
        );
        bottom: 0;
        color: mix($body-background, $body-muted-font-color, 33%);
        content: "⯆";
        display: flex;
        flex-flow: column nowrap;
        left: 0;
        justify-content: flex-end;
        min-height: 2em * $paragraph-lineheight;
        pointer-events: none;
        position: absolute;
        right: 0;
        transition-duration: 100ms;
        transition-property: color;
        transition-timing-function: ease-out;
        user-select: none;

        @include if-dark {
          background: linear-gradient(
            0deg,
            $dark-body-background,
            $dark-body-background #{0.5em * $paragraph-lineheight},
            change-color($dark-body-background, $alpha: 0)
          );
          color: mix($dark-body-background, $dark-body-muted-font-color, 50%);
        }
      }

      &:hover {
        &::after {
          color: $link-blue;
          transition-duration: 50ms;

          @include if-dark {
            color: $dark-link-blue;
          }
        }
      }
    }
  }
}

.large-cover-container {
  @include breakpoint(large) {
    min-height: 100%;
    position: relative;
  }
}

.large-cover-container-container {
  @include breakpoint(large) {
    position: relative;
  }
}

img.lazyload:not([src]) {
  opacity: 0;
}

.footer-icons {
  font-size: calc(1rem * 3 / 2);

  a {
    &:not(:focus):not(:hover) {
      color: get-color(secondary);
    }
  }

  li:not(:last-child) {
    margin-right: calc(1rem * 2 / 3);
  }
}

.footer-nav {
  a {
    &:not(:focus):not(:hover) {
      text-decoration-color: transparent;
    }
  }

  abbr {
    border-bottom: none;
  }
}

.header-nav {
  .button {
    &.caps {
      font-size: map-get($button-sizes, default);

      @include breakpoint(465px down) {
        font-size: map-get($button-sizes, small);
      }
    }

    &.clear {
      color: inherit;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.header-nav-contact.cell {
  @include breakpoint(375px) {
    @include xy-cell-base(shrink);
    @include xy-cell-size(shrink, $gutter-type: none);
  }

  .header-nav-minimal & {
    @include xy-cell-base(shrink);
    @include xy-cell-size(shrink, $gutter-type: none);
  }

  body.page-id-19 & {
    display: none;
  }
}

.header-nav-our-work-br {
  @include breakpoint(374px down) {
    display: none;
  }
  @include breakpoint(414px) {
    display: none;
  }
}

hr {
  @include if-article {
    border: none;

    @each $size in $breakpoint-classes {
      @include breakpoint($size) {
        margin-bottom: section-padding($size);
      }
    }
  }
}

hr.geometric {
  background: linear-gradient(150deg, #00aabe 0%, #0b8eb1 50%, #0b7b9b 100%);
  border: none;
  clip-path: polygon(
    0% calc(100% - 1rem),
    calc(50% - 1.5rem) 0%,
    calc(50% + 1.5rem) calc(100% - 1rem),
    100% 0%,
    100% 1rem,
    calc(50% + 1.5rem) 100%,
    calc(50% - 1.5rem) 1rem,
    0% 100%
  );
  display: block;
  height: 3rem;
  margin: 0;
  max-width: initial;
  position: relative;
  z-index: 10;
}

.icon-grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: $global-margin;
  grid-template-columns: 1fr;
  margin: 0 auto $paragraph-margin-bottom;
  text-align: left;

  @each $columns in (1 2 3) {
    @each $size in $breakpoint-classes {
      @include breakpoint($size) {
        &.#{$size}-#{$columns} {
          grid-template-columns: repeat($columns, 1fr);
        }
      }
    }
  }
}

.icon-grid-cell {
  align-items: center;
  display: flex;
}

.icon-grid-icon {
  color: get-color(secondary);
  font-size: 1.5em;
  margin-right: $global-margin;

  @include if-dark {
    color: inherit;
  }
}

.icon-grid-inline {
  display: inline-grid;
}

.icon-grid-label {
  p {
    margin-bottom: 0;
  }
}

@each $size in $breakpoint-classes {
  .icon-grid-padded-#{$size} {
    @include breakpoint($size) {
      grid-gap: section-padding($size);
    }
  }
}
.icon-grid-padded-medium,
.icon-grid-padded-large {
  @include breakpoint(medium) {
    .icon-grid-icon {
      font-size: 2.5em;
    }
  }
}

.incremental-contact-form {
  margin-bottom: $paragraph-margin-bottom;
}

.incremental-contact-form-placeholder {
  height: 16em;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: middle;
}

input.hollow,
textarea.hollow {
  $border-width: 2pt;

  color: $body-font-color;
  padding-left: 0;
  padding-right: 0;

  &,
  &:focus {
    background-color: transparent;
    border: none;
    border-bottom: $border-width solid rgba($body-font-color, 0.075);
    box-shadow: none;
    transition-duration: 200ms;
    transition-property: border-bottom-color;
    transition-timing-function: ease-out;
  }

  &:focus {
    border-bottom-color: get-color(primary);
    transition-duration: 100ms;
  }

  &::placeholder {
    color: rgba($body-muted-font-color, 0.5);
  }

  @include if-dark {
    border-bottom-color: rgba($dark-body-font-color, 0.15);
    color: $dark-body-font-color;

    &:focus {
      border-bottom-color: $dark-body-font-color;
    }

    &::placeholder {
      color: rgba($dark-body-muted-font-color, 0.5);
    }
  }

  &.no-margin-bottom {
    margin-bottom: -$border-width;
  }
}

.input-group-button {
  .button.hollow {
    border: none;
    border-bottom: 2pt solid rgba($body-font-color, 0.075);
    outline: none;

    &:focus {
      border-bottom-color: get-color(primary);
    }

    @include if-dark {
      border-bottom-color: rgba($dark-body-font-color, 0.15);

      &:focus {
        border-bottom-color: $dark-body-font-color;
      }
    }
  }
}

.label {
  &.subtle {
    color: $body-muted-font-color;

    .svg-inline--fa {
      opacity: calc(4 / 5);
    }

    @include if-dark {
      background-color: rgba($dark-body-muted-font-color, calc(1 / 6));
      color: $dark-body-muted-font-color;
    }
  }
}

.label-plain {
  display: inline;
}

button.label {
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: background-color;
  transition-timing-function: ease-out;

  &:hover {
    transition-duration: 50ms;

    &.subtle {
      background-color: scale-color(get-color(subtle), $lightness: -10%);
      color: scale-color($body-muted-font-color, $lightness: -10%);

      @include if-dark {
        background-color: rgba($dark-body-muted-font-color, calc(2 / 6));
        color: scale-color($dark-body-muted-font-color, $lightness: 33%);
      }
    }
  }
}

.multidate-preview {
  $gap: 1ch * math.div(2, 3);

  margin-bottom: 1rem;
  margin-left: -$gap;
  margin-top: -0.5rem;

  .multidate-result {
    display: inline-block;
    font-size: $label-font-size;
    margin-left: $gap;
  }
}

.photostrip {
  display: flex;
  margin-bottom: -$bleed;

  @include if-article {
    margin-bottom: $paragraph-margin-bottom;

    & + .photostrip {
      margin-top: calc(-1 * (#{$bleed} + #{$paragraph-margin-bottom}));
    }
  }
}

img.photostrip-image {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.photostrip-image-container {
  background-size: 100% 100%;
  position: relative;
}

.post-edit-link {
  color: get-color(secondary);
  transition-duration: 100ms;
  transition-property: color;
  transition-timing-function: ease-out;

  &:focus,
  &:hover {
    transition-duration: 50ms;
  }

  @include if-dark {
    &:not(:focus):not(:hover) {
      color: rgba($dark-body-font-color, 0.25);
    }
  }
}

.radio-menu-item {
  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }

  .radio-menu-item-label {
    display: block;
    font-size: $label-font-size;
    border-left: 3pt solid transparent;
    margin-bottom: 0.15em;
    padding-left: 0.5em;
    transition-duration: 100ms;
    transition-property: border-left-color;
    transition-timing-function: ease;
    user-select: none;
    white-space: nowrap;

    .badge {
      align-items: center;
      background-color: get-color(secondary);
      border-radius: 0.5rem;
      display: inline-flex;
      font-size: 0.75rem;
      font-weight: 700;
      height: 1rem;
      justify-content: center;
      line-height: 1;
      margin-left: 1ch;
      min-width: 1rem;
      padding: 0 calc(1em * 1 / 3) calc(1em * 1 / 9);
    }
  }

  input:disabled + .radio-menu-item-label {
    color: $body-muted-font-color;
  }

  input:not(:disabled):not(:checked) + .radio-menu-item-label {
    cursor: pointer;
  }

  &:active input:not(:disabled) + .radio-menu-item-label,
  input:checked + .radio-menu-item-label {
    border-left-color: get-color(secondary);
    font-weight: 700;
  }

  &:not(:active):hover {
    input:not(:disabled):not(:checked) + .radio-menu-item-label {
      border-left-color: rgba(get-color(secondary), 0.25);
    }
  }
}

.result {
  animation: 0.5s ease-out slide-in-left;
  border-color: rgba($body-font-color, 0.1);
  border-style: solid;
  border-width: 2pt 0;
  margin-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;

  &.alert {
    border-color: get-color(primary);
  }

  @include if-dark {
    border-color: rgba($dark-body-font-color, 0.3);

    &.alert {
      border-color: $dark-body-font-color;
    }
  }
}

.revealable-field-links {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.revealed-field {
  animation: 0.5s ease-out slide-in-left;
}

.review {
}

.review-author {
  font-weight: bold;
}

.review-body {
}

.review-rating {
  abbr {
    border-bottom: none;
    cursor: inherit;
  }
}

.review-star {
  color: $rating-yellow;
}

.responsive-embed-spotify {
  @include breakpoint(small only) {
    padding-bottom: 280px;
  }
}

.series-intro {
  font-size: $small-font-size;
  font-style: italic;
  margin-bottom: 2rem;
  text-align: center;
}

.show-for-error {
  body:not(.error) & {
    display: none;
  }
}

ul.packed-logos {
  list-style-type: none;
  margin-left: 0;

  > li {
    cursor: default;
    display: inline-block;
    margin: 0 calc(1rem * 1 / 3) calc(1rem * 1 / 6);
    user-select: none;

    @include breakpoint(medium) {
      margin: 0 calc(1rem * 1 / 2) calc(1rem * 1 / 4);
    }

    img {
      $height-small: 2rem;
      $height-medium: 2.5rem;
      $height-final: 3rem;

      max-height: $height-small;
      width: auto;

      @include breakpoint(medium) {
        max-height: $height-medium;
      }

      @include breakpoint(final) {
        max-height: $height-final;
      }

      // Work around IE SVG sizing bug.
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: $height-small;
        width: 100%;

        @include breakpoint(medium) {
          height: $height-medium;
        }

        @include breakpoint(final) {
          height: $height-final;
        }
      }
    }
  }
}

.spacer {
  height: calc(1em * 5 / 4);
  visibility: hidden;
}

.unresponsive-embed {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > *:last-child {
    margin-bottom: 0;
  }
}

.wp-video {
  margin-bottom: $paragraph-margin-bottom;
  margin-left: auto;
  margin-right: auto;
}
