@use "sass:math";

$dual-action-layout-overhang: 6rem;
$dual-action-layout-overhang-stacked: 3rem;
$section-bowtie-overhang: 0.5rem;
$h1-layout-overhang: 2rem;

.article-layout-container {
  max-width: 50rem;
}

.article-layout-footer {
  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      margin-top: section-padding($size);
    }
  }
}

body {
  min-width: $global-min-width;
}

.catalog-card-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

  @each $breakpoint, $margin in $grid-margin-gutters {
    @include breakpoint($breakpoint) {
      grid-gap: rem-calc($margin);
    }
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
}

.clients-layout-heading {
  margin-bottom: $global-margin;

  @include breakpoint(small only) {
    margin-right: -6ch;
    transform: rotate(-90deg) translateY(-3ch);
  }
}

.clients-layout-logos {
  margin-bottom: math.div($global-margin, 2);
  margin-top: math.div(-$global-margin, 2);

  @include breakpoint(small only) {
    margin-left: calc(-1 * #{rem-calc(map-get($grid-margin-gutters, small))});
    margin-right: calc(-1 * #{math.div(rem-calc(map-get($grid-container-padding, small)), 2)});
  }

  & > .packed-logos {
    margin-bottom: 0;
  }
}

.contact-form-main {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;

    > label {
      display: flex;
      flex: 1 0;
      flex-direction: column;

      textarea {
        flex: 1 auto;
        resize: vertical;
      }
    }
  }
}

.dual-action-layout {
  background: linear-gradient(150deg, #da502d 0%, #d33535 40%, #cc2e3d 100%);
  position: relative;
  z-index: 0;
}

.dual-action-layout-grid {
  $aspect-ratio: math.div(16, 9);

  @include breakpoint(medium) {
    height: calc(100vw / #{$aspect-ratio});
    max-height: 80vh;
    position: relative;

    .dual-action-layout-compact & {
      height: auto;
    }
  }

  @include breakpoint(final) {
    height: calc(#{rem-calc(map-get($breakpoints, final))} / #{$aspect-ratio});

    .dual-action-layout-compact & {
      height: auto;
    }
  }
}

@each $side in (left, right) {
  .dual-action-layout-grid > .cell {
    @include breakpoint(small only) {
      $padding: math.div(rem-calc(map-get($grid-container-padding, small)), 2);

      padding-#{$side}: $padding;
    }
  }

  .dual-action-layout-#{$side}-edge-cell {
    // Specificity
    .grid-padding-x > &.cell {
      @each $size in (small, medium) {
        @include breakpoint($size) {
          $padding: math.div(rem-calc(map-get($grid-container-padding, $size)), 2);

          padding-#{$side}: $padding;
        }
      }

      @include breakpoint(final) {
        $padding: math.div(rem-calc(map-get($grid-container-padding, medium)), 2);

        padding-#{$side}: calc((100% - #{$grid-container}) / 2 + #{$padding});
      }
    }
  }
}

.dual-action-layout-heading-cell {
  @include breakpoint(small only) {
    margin-top: section-padding(small);
  }

  @include breakpoint(medium) {
    white-space: nowrap;
  }
}

.dual-action-layout-left-cell {
  @include breakpoint(small only) {
    padding-bottom: $global-margin;
    padding-top: $global-margin * 2;
    position: relative;
  }

  @include breakpoint(medium) {
    height: 100%;
    position: relative;
  }
}

.dual-action-layout-right-cell {
  @include breakpoint(small only) {
    clip-path: polygon(
      0% calc(#{$dual-action-layout-overhang-stacked} * 1 / 2),
      100% calc(-1 * #{$dual-action-layout-overhang-stacked} * 1 / 2),
      100% 100%,
      0% 100%
    );
    padding-bottom: $global-margin;
    padding-top: $global-margin * 2;
    position: relative;

    &::after {
      background: linear-gradient(150deg, #4b6768ea 0%, #424d4ef1 50%, #394649df 100%);
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: calc(-1 * #{$dual-action-layout-overhang-stacked} * 1 / 2);
      z-index: -10;
    }
  }

  @include breakpoint(medium) {
    clip-path: polygon(#{$dual-action-layout-overhang} 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 100%;
    position: relative;

    &::after {
      background: linear-gradient(150deg, #4b6768ea 0%, #424d4ef1 50%, #394649df 100%);
      bottom: 0;
      content: "";
      display: block;
      left: calc(-1 * #{$dual-action-layout-overhang} * 1 / 2);
      position: absolute;
      right: 0;
      top: -0.5px;
      z-index: -10;
    }
  }
}

.dual-action-layout-right-cell-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;

  &.ratio-box {
    height: auto;
    position: absolute;
    padding-bottom: 0 !important;
    width: auto;
  }

  @include breakpoint(small only) {
    top: calc(-1 * #{$dual-action-layout-overhang-stacked} * 1 / 2);
  }

  @include breakpoint(medium) {
    left: calc(-1 * #{$dual-action-layout-overhang} * 1 / 2);
    top: -$bleed;
  }
}

.dual-action-layout-left-inner-grid,
.dual-action-layout-right-inner-grid {
  @include breakpoint(medium) {
    height: 100%;
  }
}

.dual-action-layout-compensation {
  @include breakpoint(small only) {
    height: $dual-action-layout-overhang-stacked;
  }
}

.footer-layout-grid {
  display: grid;
  grid-template-areas:
    "about"
    "contact"
    "explore"
    "services"
    "reference"
    "careers";

  @include breakpoint(medium) {
    grid-template-areas:
      "about contact   services"
      "about explore   services"
      "about reference services"
      "about careers   careers";
  }
}

@each $area in (about, careers, contact, explore, reference, services) {
  .footer-layout-#{$area} {
    grid-area: $area;
  }
}

.footer-layout-about {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.gallery-header-layout {
  background: radial-gradient(#000a, #0007);
  position: relative;
}

.gallery-header-layout-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;

  &.ratio-box {
    height: auto;
    position: absolute;
    padding-bottom: 0 !important;
    width: auto;
  }

  img {
    filter: grayscale(1) brightness(0.9) contrast(1.5);
  }
}

.gallery-header-layout-grid {
  min-height: 20rem;

  @media (max-height: 45rem) {
    min-height: calc(100vh * 1 / 3);
  }
}

.gallery-header-layout-preamble {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  text-align: center;

  @include breakpoint(medium) {
    width: 50%;
  }
}

.h1-layout {
  background: linear-gradient(150deg, #dd521b 0%, #d33535 35%, #cc243d 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - #{$h1-layout-overhang}), 0% 100%);
  // margin-bottom: calc(-1 * (#{$h1-layout-overhang} + #{$bleed}));
  // padding-bottom: calc(#{$h1-layout-overhang} * 4 / 3);
  padding-bottom: calc(1rem + #{$h1-layout-overhang} * (1 / 6));
  padding-top: 1rem;

  // Specificity
  &.text-dark {
    h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.p) {
      &:not(.plain)::before {
        background-color: #00a4d1;
      }
    }
  }
}

.h1-layout-compensation {
  height: $h1-layout-overhang;
}

.header-layout {
  $padding: 1rem * math.div(1, 2);

  padding-bottom: $padding;
  padding-top: $padding;

  &:not(.header-layout-transparent) {
    background-color: #fff;
    box-shadow: 0 0 0.5rem #0000001c;
  }

  &.header-layout-transparent {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}

.images-layout {
  margin-left: auto;
  margin-right: auto;
  max-width: calc((100vh - 3rem) * (16 / 9));
  position: relative;

  @include breakpoint(medium) {
    display: flex;
    flex-flow: row nowrap;
  }

  @media (min-width: 1280px) {
    @include xy-grid-container;
  }
}

.images-layout-main {
  height: 0;
  position: relative;

  @include breakpoint(small only) {
    padding-bottom: calc(100% / (16 / 9)) !important;
    width: 100% !important;
  }
}

.images-layout-main-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &:not(.active) {
    display: none;
  }

  & > .ratio-box {
    height: 100%;
    padding-bottom: 0 !important;
    position: absolute;
    width: 100%;
  }
}

.images-layout-nav {
  @include breakpoint(small only) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 1pt;
  }

  @include breakpoint(medium) {
    display: grid;
    flex-grow: 1;
    grid-auto-flow: column;
  }
}

.images-layout-nav-item {
  @include breakpoint(small only) {
    margin: 1pt;

    & > .ratio-box {
      height: $touch-target-size;
      padding-bottom: 0 !important;
      width: $touch-target-size;
    }
  }

  @include breakpoint(medium) {
    position: relative;

    & > .ratio-box {
      height: 100%;
      padding-bottom: 0 !important;
      position: absolute;
      width: 100%;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

@each $size in medium large {
  @each $side in left right {
    .#{$size}-#{$side} {
      @include breakpoint($size) {
        float: $side;
        width: 50%;

        @include if-article {
          margin-#{direction-opposite($side)}: $global-margin;
        }
      }
    }
  }
}

@each $size in $breakpoint-classes {
  @include breakpoint($size) {
    @if $size == $-zf-zero-breakpoint {
      .text-dark {
        color: $dark-body-font-color;
      }

      .section-dark {
        background-color: $dark-body-background;
      }
    } @else {
      .#{$size}-text-dark {
        color: $dark-body-font-color;
      }

      .#{$size}-section-dark {
        background-color: $dark-body-background;
      }
    }
  }
}

.section-bowtie {
  clip-path: polygon(
    0% 0%,
    50% #{$section-bowtie-overhang},
    100% 0%,
    100% 100%,
    50% calc(100% - #{$section-bowtie-overhang}),
    0% 100%
  );
  margin-bottom: calc(-1 * (#{$section-bowtie-overhang} + #{$bleed}));
  margin-top: calc(-1 * (#{$section-bowtie-overhang} + #{$bleed}));
  padding-bottom: calc(#{$section-bowtie-overhang});
  padding-top: calc(#{$section-bowtie-overhang} + #{$global-padding});
  position: relative;
  z-index: 20;

  &.blue {
    background: linear-gradient(150deg, #00aabe 0%, #0b8eb1 50%, #0b7b9b 100%);
  }

  &.red {
    background: linear-gradient(150deg, #cc3d2f 0%, #cc3339 50%, #cc2f3d 100%);
  }
}

.section-bowtie-compensation {
  height: $section-bowtie-overhang;
}

.section-padded {
  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      $padding: section-padding($size);

      padding-bottom: calc(#{$padding} - #{$global-padding});
      padding-top: $padding;
    }
  }
}

.section-padded-grid {
  @each $breakpoint, $margin in $grid-margin-gutters {
    @include breakpoint($breakpoint) {
      $padding: rem-calc($margin);

      padding-bottom: calc(#{$padding} - #{$global-padding});
      padding-top: $padding;
    }
  }
}

@each $size in $breakpoint-classes {
  @include breakpoint($size) {
    $padding: section-padding($size);

    @if $size == $-zf-zero-breakpoint {
      .section-side-padded {
        padding-left: $padding;
        padding-right: $padding;
      }
    } @else {
      .#{$size}-section-side-padded {
        padding-left: $padding;
        padding-right: $padding;
      }
    }
  }
}

.section-pnw {
  background-image: linear-gradient(rgba(17, 60, 66, 0.86), rgba(0, 45, 51, 0.9)),
    url("./washington.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 45%;
}

.baz-layout {
  background: radial-gradient(#131e25cc, #14142088);
  position: relative;

  h1 .phrasing-block::after {
    content: "";
    border-bottom: 0.5rem solid #00a4c2;
    display: block;
    margin: 1rem auto 0.5rem;
    width: 6rem;
  }
}

.baz-layout-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;

  &.ratio-box {
    height: auto;
    position: absolute;
    padding-bottom: 0 !important;
    width: auto;
  }
}

.baz-layout-grid {
  // height: calc(100vw / (21 / 9));
  min-height: 30rem;

  @media (max-height: 45rem) {
    min-height: calc(100vh * 2 / 3);
  }
}

.tile-menu-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

  @include breakpoint(medium) {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  &.front-page {
    $columnses: (
      small: 2,
      medium: 3,
      wide: 6,
    );

    @each $breakpoint, $columns in $columnses {
      @include breakpoint($breakpoint) {
        grid-template-columns: repeat(#{$columns}, 1fr);
      }
    }

    @include breakpoint(large) {
      .tile-menu-layout-cell {
        padding-bottom: 100% * math.div(9, 16);
      }
      .tile-menu-layout-cell-background {
        padding-bottom: 100% * math.div(9, 16) !important;
      }
    }
    @include breakpoint(wide) {
      .tile-menu-layout-cell {
        padding-bottom: 100%;
      }
      .tile-menu-layout-cell-background {
        padding-bottom: 100% !important;
      }
    }
  }
}

.tile-menu-layout-cell {
  height: 0;
  padding-bottom: 100%;
  position: relative;

  @include breakpoint(medium) {
    font-size: 1.25rem;
  }

  @include breakpoint(large) {
    font-size: 1.4rem;
  }
}

a.tile-menu-layout-cell {
  &:active,
  &:focus,
  &:hover {
    .tile-menu-layout-content {
      transform: translateX(-1em);
      transition-duration: 200ms;

      &::after {
        opacity: 1;
        transform: translateX(0);
        transition-duration: 200ms;
      }
    }
  }
}

.tile-menu-layout-content {
  $padding: $global-padding;

  font-weight: 300;
  padding: $padding;
  position: relative;
  text-align: right;
  transform: translateX(0);
  transition-duration: 200ms;
  transition-property: transform;
  transition-timing-function: ease;

  &::after {
    align-items: center;
    bottom: 0;
    content: "\203a";
    display: flex;
    font-size: 2em;
    font-weight: 400;
    left: calc(100% - #{$padding} + 1em * (1 / 4));
    opacity: 0;
    position: absolute;
    top: -0.15em;
    transform: translateX(-0.25em);
    transition-duration: 200ms;
    transition-property: opacity, transform;
    transition-timing-function: ease;
  }

  strong {
    font-weight: 700;

    @include breakpoint(medium) {
      white-space: nowrap;
    }
  }
}

.tile-menu-layout-cell-background {
  padding-bottom: 100% !important;
}

.tile-menu-layout-cell-foreground {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.tile-menu-layout-cell-foreground {
  align-items: flex-end;
  background: linear-gradient(
    0deg,
    #131e25dd,
    #131e2599 calc(#{$global-padding} + 3em),
    #131e2500 calc(#{$global-padding} + 3em + 4em),
    #131e2500
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
